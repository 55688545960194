import React, { Component } from 'react';

//glamorous
import { Logo, A, Image } from './styles';

class LogoComponent extends Component {
  render() {
    const { collapsed } = this.props;

    if (collapsed) {
      return (
        <A to="/">
          <Logo collapsed={collapsed}>{`<ReactAcademy/>`}</Logo>
        </A>
      );
    }

    return <Image />;
  }
}

export default LogoComponent;
