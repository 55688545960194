import emotion from '@emotion/styled';

import flex from 'styles/flex';
import { queries } from 'styles/responsive';

//components
import $WorkshopPoint from 'components/WorkshopPoint';

import {
  Grid as $Grid,
  Wrapper as $Wrapper,
  Button as $Button
} from 'styles/shared-components';

export const Grid = emotion($Grid)({
  marginTop: 100
});

export const Wrapper = emotion($Wrapper)({
  ...flex.vertical
});

export const Note = emotion.div({
  fontSize: 18,
  lineHeight: '25px',
  textAlign: 'center',
  margin: 'auto',
  marginTop: 100,
  color: 'gray',
  maxWidth: 900,
  [queries.largeWidth]: {
    marginTop: 50
  }
});

export const TryLabsSection = emotion.div({});

export const Button = emotion($Button)({
  width: 250,
  margin: 'auto',
  marginTop: 100,
  position: 'relative',
  zIndex: 9999999,
  [queries.largeWidth]: {
    margin: 'auto',
    marginTop: 50
  },
  [queries.smallWidth]: {
    fontSize: 24,
    borderWidth: 2,
    width: 160
  }
});

export const WorkshopPoint = emotion($WorkshopPoint)({});

export const LinkButton = Button.withComponent('a');
