import React, { Component } from 'react';

//glamorous
import {
  WorkshopPoint,
  IconAndTitle,
  Title,
  Description,
  Icon
} from './styles';

//external
import LazyLoad from 'components/LazyLoader';

class WorkshopPointComponent extends Component {
  render() {
    const {
      icon,
      style,
      title,
      hideIconOnDesktop,
      extra,
      className,
      description
    } = this.props;
    return (
      <WorkshopPoint style={style} className={className}>
        <IconAndTitle>
          {icon && (
            <LazyLoad height={100}>
              <Icon hideIconOnDesktop={hideIconOnDesktop} src={icon} />
            </LazyLoad>
          )}
          <Title>{title}</Title>
        </IconAndTitle>
        <Description>{description}</Description>
        {extra}
      </WorkshopPoint>
    );
  }
}

export default WorkshopPointComponent;
