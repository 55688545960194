import React, { Component, Fragment } from 'react';

//dates
import format from 'date-fns/format';

//lodash
import orderBy from 'lodash/orderBy';

//styles
import * as S from './styles';
import * as A from 'styles/shared-components';

//components
import { getWorkshopDate } from 'utils/date-utils';

class SingleWorkshopSection extends Component {
  render() {
    const { workshop } = this.props;

    if (!workshop) {
      return null;
    }

    const {
      title: workshopTitle,
      city,
      color,
      backgroundColor,
      country,
      ticketsLink,
      workshopType: { description, title: typeTitle },
      soldOut,
      workshopDates = []
    } = workshop;

    const title = workshopTitle || `${typeTitle} Workshop`;
    const hasTicketsLink = ticketsLink && ticketsLink !== '';

    const humanDate = getWorkshopDate(
      workshop.dateStart,
      workshop.dateEnd,
      undefined,
      'DD MMMM YYYY'
    );

    const hasOneDate = workshopDates.length === 0;
    const hasMoreDates = workshopDates.length > 0;

    const sortedWorkshopDates = orderBy(
      workshopDates,
      workshop => workshop.date
    );

    const isCollabWithConference =
      workshop.conferenceName && workshop.conferenceUrl;
    return (
      <S.SingleWorkshopSection>
        <S.Wrapper>
          <A.Vertical centerH>
            <A.Vertical centerH>
              <S.Title centered>{title}</S.Title>
              {!isCollabWithConference && (
                <Fragment>
                  <A.Space size={5} />
                  <S.Description>{description}</S.Description>
                </Fragment>
              )}
            </A.Vertical>

            <A.Space size={7} />

            <A.Horizontal centerV spaceAll={15}>
              <S.Location>
                {city}, {country}
              </S.Location>
              {hasOneDate && (
                <Fragment>
                  <S.Separator />
                  <S.Date>{humanDate}</S.Date>
                </Fragment>
              )}
            </A.Horizontal>

            <A.Space size={8} />

            {hasOneDate && (
              <A.WhiteButton
                backgroundColor={backgroundColor}
                color={color}
                disabled={!hasTicketsLink || soldOut}
                {...hasTicketsLink && {
                  href: ticketsLink,
                  target: '_blank'
                }}
              >
                {do {
                  if (soldOut) {
                    ('Sold out');
                  } else if (hasTicketsLink) {
                    ('Register');
                  } else {
                    ('Tickets Coming Soon');
                  }
                }}
              </A.WhiteButton>
            )}

            {hasMoreDates && (
              <Fragment>
                <S.DatesButtons>
                  {sortedWorkshopDates.map(date => {
                    const formattedDate = format(date.date, 'DD.MM.YYYY');

                    const canBuyTickets =
                      date.comingSoon === false &&
                      !date.soldOut &&
                      date.ticketsUrl;

                    return (
                      <Fragment>
                        <S.DateButton
                          size="small"
                          key={date.id}
                          backgroundColor={backgroundColor}
                          color={color}
                          disabled={!canBuyTickets}
                          {...canBuyTickets && {
                            href: date.ticketsUrl,
                            target: '_blank',
                            rel: 'noopener'
                          }}
                        >
                          {do {
                            if (date.buttonTitle) {
                              date.buttonTitle;
                            } else if (date.soldOut) {
                              `${formattedDate} (Sold out)`;
                            } else if (date.comingSoon) {
                              `${formattedDate} (Coming Soon)`;
                            } else {
                              if (canBuyTickets) {
                                `Register for ${formattedDate}`;
                              } else {
                                ('Tickets Coming Soon');
                              }
                            }
                          }}
                        </S.DateButton>
                        <A.Space size={3} />
                      </Fragment>
                    );
                  })}
                </S.DatesButtons>
              </Fragment>
            )}

            <A.Space size={4} />


            {isCollabWithConference && (
              <S.Collaboration>
                The workshop is organized in collaboration with{' '}
                <S.WorkshopLink
                  target="_blank"
                  rel="noopener"
                  href={workshop.conferenceUrl}
                >
                  {workshop.conferenceName}
                </S.WorkshopLink>.
                {workshop.hasComboTickets && (
                  <Fragment>
                    <S.CollaborationBreak />
                    <span>
                      {' '} You can get discounted combo tickets for the workshop and
                      the conference.
                    </span>
                  </Fragment>
                )}
              </S.Collaboration>
            )}

            <S.PrintableLink
              rel="noopener"
              target="_blank"
              href={`${workshop.slug}/printable`}
            >
              see printable version
            </S.PrintableLink>
          </A.Vertical>
        </S.Wrapper>
      </S.SingleWorkshopSection>
    );
  }
}

export default SingleWorkshopSection;
