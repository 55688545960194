import times from 'lodash/times';
import { lighten } from 'polished';
import flex from './flex';
import { css, keyframes } from 'emotion';
import { queries } from './responsive';
import { animations } from './animations';

export const cond = (condition, rule) => (condition ? rule : '');

export const backgroundImage = imageUrl => ({
  background: `url(${imageUrl})`
});

export const horizontalGradient = (from, to) => ({
  background: `linear-gradient(to right, ${from}, ${to})`
});

export const horizontalGradientFromLight = (from, amount = 1) =>
  horizontalGradient(lighten(amount, from), from);

export const size = (width, height = width) => ({
  width,
  height
});

export const animateVisibility = p => ({
  visibility: 'hidden'
});

export const animation = (animation, ms = 1000) => p => ({
  visibility: 'hidden',
  ...(p.entered && {
    visibility: 'visible',
    animation: `${keyframes(animation)} ${ms}ms`
  })
});

export const animated = (animation, ms, delay) => ({
  animation: `${keyframes(animation)} ${ms}ms forwards`,
  animationDelay: `${delay}ms`
});

export const fixedSize = (width, height = width) => ({
  width,
  height,
  maxWidth: width,
  maxHeight: height,
  minWidth: width,
  minHeight: height
});

export const iconSize = (size, fontSize = size) => ({
  fontSize,
  width: size,
  height: size
});

export const grid = (
  cols = 3,
  gridColumnGap = 0,
  gridRowGap = gridColumnGap,
  {
    debug,
    fallbackParent = {
      ...flex.vertical
    },
    fallbackChild = {
      marginBottom: gridRowGap
    }
  } = {}
) => {
  let gridTemplateColumns = '';
  times(cols, t => (gridTemplateColumns += '1fr '));

  return {
    '@supports (display: grid)': {
      display: 'grid',
      ...(debug && {
        border: '1px solid blue',
        '> *': {
          border: '1px solid red'
        }
      }),
      gridGap: gridColumnGap,
      ...(gridColumnGap !== gridRowGap && {
        gridRowGap,
        gridColumnGap
      }),
      gridTemplateColumns
    },
    [`@supports not (display: grid)`]: {
      ...fallbackParent,
      '& > *': {
        ...fallbackChild
      }
    }
  };
};

export const buttonify = {
  cursor: 'pointer',
  outline: 'none',
  background: 'none'
};

export const cardShadow = {
  boxShadow: `rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px`
};

export const whenHovering = (className, rule, extraRules) => ({
  '&:hover': {
    [`& .${className}`]: rule,
    ...extraRules
  }
});

export const createZIndexGetter = zIndexes => element => ({
  zIndex: zIndexes.indexOf(element)
});

export const grayscale = (grayscale = 1) => ({
  filter: `grayscale(${grayscale})`
});

export const padding = {
  horizontal: (left, right = left) => ({
    paddingLeft: left,
    paddingRight: right
  }),
  vertical: (left, right = left) => ({
    paddingTop: left,
    paddingBottom: right
  }),
  both: (vertical, horizontal = vertical) => ({
    paddingTop: vertical,
    paddingBottom: vertical,
    paddingLeft: horizontal,
    paddingRight: horizontal
  })
};

export const margin = {
  horizontal: (left, right = left) => ({
    marginLeft: left,
    marginRight: right
  }),
  vertical: (top, bottom = top) => ({
    marginTop: top,
    marginBottom: bottom
  }),
  both: (vertical, horizontal = vertical) => ({
    marginTop: vertical,
    marginBottom: vertical,
    marginLeft: horizontal,
    marginRight: horizontal
  })
};

export const animate = (sec = '150ms', type = 'linear') => ({
  transition: `all ${sec} ${type}`
});

export const animateGrayscale = (from = 1, to = 0) => ({
  ...grayscale(from),
  ...animate(),
  '&:hover': {
    ...grayscale(to)
  }
});

export const underParent = (className, style) => ({
  [`.${className} &`]: style
});

export const translate = (amount = 0, side = '', unit = 'px') => ({
  transform: `translate${side}(${amount}${unit})`
});

export const unstyleLink = (color = 'black') => ({
  textDecoration: 'none',
  color
});

export const unstyleButton = {
  background: 'none',
  outline: 'none',
  border: 'none'
};

export const acceptsAnimateProps = ({ animate, delay, visible, ms, name }) => ({
  animate,
  delay,
  visible,
  ms,
  name
});

export const acceptsAnimate = ({
  delay = 1,
  visible,
  ms = 200,
  name = 'fadeIn'
} = {}) => ({
  visibility: 'hidden',
  ...(visible && {
    ...animated(animations[name], ms, delay),
    [queries.smallWidth]: {
      ...animated(animations.fadeIn, ms, delay)
    }
  })
});

export const unlink = {
  textDecoration: 'none',
  color: 'black'
};

export const unlinkChild = {
  '& a': {
    ...unlink
  }
};
