import React, { Component } from 'react';

//glamorous
import {
  TopicBlock,
  Title,
  Logo,
  LogoWrap,
  Logos,
  Description,
  Inside
} from './styles';
import { Vertical } from 'styles/flex-components';

import LazyLoad from 'components/LazyLoader';

class TopicBlockComponent extends Component {
  render() {
    const { topic, className, style, centered = false } = this.props;

    const { title, description, topicLogo } = topic;
    const logos = topicLogo.map(({ logo, style }) => ({
      url: logo.url,
      style
    }));

    return (
      <TopicBlock style={style} centered={centered} className={className}>
        <Inside centered={centered}>
          <Vertical centerV>
            {logos && (
              <Logos centered={centered}>
                {logos.map((logo, index) => (
                  <LogoWrap key={index}>
                    <LazyLoad>
                      <Logo
                        styles={logo.style}
                        width={logo.width}
                        src={logo.url}
                      />
                    </LazyLoad>
                  </LogoWrap>
                ))}
              </Logos>
            )}
            <Title centered={centered}>{title}</Title>
          </Vertical>
          <Description centered={centered}>{description}</Description>
        </Inside>
      </TopicBlock>
    );
  }
}

export default TopicBlockComponent;
