import emotion from '@emotion/styled';

export const Background = emotion.div(
  {
    position: 'absolute',
    width: '100%',
    transition: 'all 150ms linear'
  },
  p => ({
    ...(p.gradient && {
      background: 'linear-gradient(192deg,#b2cbe1,#f6f9fc 42%)'
    }),
    ...(p.skewed && {
      transform: `skewY(-5deg)`
    }),
    ...(p.color && {
      backgroundColor: p.color
    }),
    ...(p.above && {
      zIndex: 999
    }),
    ...(p.topDown && {
      background: 'linear-gradient(to bottom,#b2cbe1,#ffffff 42%)'
    }),
    ...(p.custom && p.custom),
    height: p.height || '100%'
  })
);
