import emotion from "@emotion/styled";
import flex from "styles/flex";
import colors from "styles/colors";
import { queries } from "styles/responsive";

export const WorkshopEmailSubscribe = emotion.div(
  {
    ...flex.vertical,
    ...flex.centerVertical,
    margin: "auto",
    maxWidth: 650
  },
  p => ({
    ...(p.withSpace && {
      padding: "100px 50px",
      [queries.mediumWidth]: {
        padding: "50px 20px"
      }
    })
  })
);

const SECTION_HEIGHT = 70;

export const Input = emotion.input({
  ...flex.vertical,
  ...flex.centerVertical,
  height: SECTION_HEIGHT,
  fontSize: 18,
  lineHeight: "20px",
  borderRadius: 15,
  backgroundColor: "white",
  maxWidth: 600,
  width: "100%",
  color: "#505050",
  padding: "10px 20px",
  border: "1px solid #c5c5c5",
  margin: "auto",
  outline: "none",
  paddingRight: 70,
  "&::placeholder": {
    position: "relative",
    top: 5
  }
});

export const Title = emotion.div({
  fontSize: 20,
  marginBottom: 25,
  textAlign: "center",
  color: "#32325d"
});

export const Button = emotion.input({
  outline: "none",
  border: 0,
  borderRadius: "100%",
  position: "absolute",
  right: 10,
  top: 10,
  backgroundColor: "#0181c9",
  padding: "10px 5px",
  color: "white",
  cursor: "pointer",
  transition: "all 150ms linear",
  height: 50,
  width: 50,
  margin: "auto",
  fontSize: 15,
  fontWeight: "300",
  WebkitAppearance: "none",
  "&:hover": {
    backgroundColor: "#014dc9"
  },
  "&:active": {
    backgroundColor: "#0436c9",
    transform: "translateY(1px)"
  }
});

export const Thanks = emotion.div({
  fontSize: 18,
  color: colors.textColor
});

export const Form = emotion.form({
  position: "relative",
  maxWidth: 380,
  width: "100%",
  ...flex.horizontal,
  ...flex.centerHorizontal,
  height: SECTION_HEIGHT
});
