import styled from '@emotion/styled';
import * as A from 'styles/shared-components';
import colors from 'styles/colors';

export const FaqView = styled(A.Section)({});

export const Wrap = styled(A.Wrapper)({
  maxWidth: 600,
  padding: 0
});

export const Title = styled(A.Title)({
  lineHeight: '70px',
  marginBottom: 50
});

export const Questions = styled.div({
  maxWidth: 700
});

export const Question = styled.div`
  margin-bottom: 20px;
  padding: 17px 20px;
  transition: all 50ms linear;
  border: 1px solid rgba(0, 0, 0, 0);
  color: ${colors.titleColor};
`;

export const QuestionTitle = styled.div`
  font-size: 23px;
  margin-bottom: 20px;
  line-height: 33px;
`;

export const Answer = styled.div`
  font-size: 18px;
  color: ${colors.textColor};
  line-height: 25px;
`;

export const Link = styled.a({
  color: '#565656'
});
