import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { grid } from 'styles/mixins';
import { queries } from 'styles/responsive';

//components
import GraphCmsImage from 'components/GraphCmsImage';

//shared-components
import {
  Section,
  Title as $Title,
  Subtitle as $Subtitle
} from 'styles/shared-components';

export const StudentsSection = emotion(Section)({
  ...flex.vertical,
  ...flex.centerVerticalH,
  backgroundColor: 'white'
});

export const Quotes = emotion.div({
  ...flex.horizontal,
  ...flex.wrap,
  [queries.smallWidth]: {
    ...flex.vertical
  }
});

export const Subtitle = emotion($Subtitle)({});

export const Title = emotion($Title)({
  lineHeight: '55px'
});
