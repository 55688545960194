import React, { Component } from 'react';

//glamorous
import { Title, Subtitle, Wrapper } from 'styles/shared-components';

import { LabsSection, Laptop, Video } from './styles';

//components
import Background from 'components/Background';

//videos
import labVideo from 'videos/lab.mp4';

import { sections } from 'styles/config';
const { labs } = sections;

//animation
import { AnimateIn } from 'react-genie';
import { animations } from 'styles/animations';

class LabsSectionComponent extends Component {
  render() {
    return (
      <LabsSection {...labs.section}>
        <Background {...labs.background} />
        <Wrapper>
          <Title {...labs.title} centered>
            React Academy Labs
          </Title>
          <Subtitle {...labs.subtitle}>
            A presentation is worth nothing without some practical work. Our
            unique web app will guide the attendees through the exercises, in
            which they will solve actual real life problems. <br />
          </Subtitle>

          <AnimateIn duration={1000} animationName={animations.fadeInUp}>
            <div>
              <Laptop {...labs.laptop}>
                <Video
                  placeholderColor="#19181a"
                  delay={5000}
                  width={1218}
                  height={754}
                  src={labVideo}
                />
              </Laptop>
              <Video
                withoutLaptop
                placeholderColor="#19181a"
                delay={5000}
                width={1218}
                height={754}
                src={labVideo}
              />
            </div>
          </AnimateIn>
        </Wrapper>
      </LabsSection>
    );
  }
}

export default LabsSectionComponent;
