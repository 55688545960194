import emotion from '@emotion/styled';
import flex from 'styles/flex';
import colors from 'styles/colors';
import { horizontalGradientFromLight, margin, padding } from 'styles/mixins';
import { queries } from 'styles/responsive';
import { Section } from 'styles/shared-components';
import { Wrapper as $Wrapper } from 'styles/shared-components';

export const TrainerSection = emotion(Section)({
  position: 'relative',
  overflow: 'hidden',
  ...flex.horizontal
});

export const Wrapper = emotion($Wrapper)({
  ...padding.horizontal(50),
  ...padding.vertical(50, 0),
  [queries.largeWidth]: {
    ...padding.vertical(50, 50)
  }
});

export const Avatar = emotion.img(p => ({
  ...(p.top && {
    borderRadius: '100%',
    boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.33)',
    position: 'relative',
    width: 150,
    height: 150,
    objectFit: 'cover',
    margin: 'auto',
    backgroundColor: 'white',
    marginBottom: 30,
    [queries.aboveLarge]: {
      display: 'none'
    }
  }),
  ...(p.bottom && {
    height: 'auto',
    objectFit: 'cover',
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: 450,
    [queries.largeWidth]: {
      display: 'none'
    }
  }),
  ...(p.rotated && {
    transform: 'rotate(-5deg)',
    bottom: 19
  })
}));

export const Container = emotion.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  [queries.largeWidth]: {
    ...flex.vertical,
    ...flex.centerVerticalH
  }
});

export const Left = emotion.div({
  flex: 1,
  position: 'relative',
  padding: '30px 50px 50px 50px',
  [queries.largeWidth]: {
    ...flex.vertical,
    ...flex.centerVerticalV,
    padding: 0,
    marginBottom: 15
  }
});

export const Title = emotion.div({
  fontSize: 50,
  lineHeight: '50px',
  color: colors.titleColor,
  [queries.largeWidth]: {
    textAlign: 'center',
    fontSize: 35,
    lineHeight: '45px'
  }
});

export const Name = emotion.div({
  fontSize: 30,
  color: colors.subtitleColor,
  marginBottom: 50,
  [queries.largeWidth]: {
    textAlign: 'center',
    fontSize: 23,
    lineHeight: '30px',
    ...margin.vertical(15, 30)
  }
});

export const SocialLinks = emotion.div({});

export const Text = emotion.div({
  width: 350,
  textAlign: 'left',
  fontSize: 16,
  lineHeight: '25px',
  [queries.largeWidth]: {
    // textAlign: 'center',
    margin: 'auto',
    width: '100%',
    maxWidth: 400
  }
});

export const Link = emotion.a({
  textDecoration: 'none',
  borderBottom: '1px dashed gray',
  color: '#565656'
});
