export const flex = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  horizontalInverse: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  verticalInverse: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  centerHorizontal: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  horizontalDefault: {
    justifyContent: 'initial',
    alignItems: 'initial'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  centerHorizontalH: {
    justifyContent: 'center'
  },
  centerHorizontalV: {
    alignItems: 'center'
  },
  centerVertical: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  verticalDefault: {
    alignItems: 'initial',
    justifyContent: 'initial'
  },
  centerVerticalH: {
    alignItems: 'center'
  },
  centerVerticalV: {
    justifyContent: 'center'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  },
  alignEnd: {
    alignItems: 'flex-end'
  },
  alignStart: {
    alignItems: 'flex-start'
  }
};

export default flex;
