import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { Wrapper as $Wrapper } from 'styles/shared-components';
import { queries } from 'styles/responsive';

export const FooterSection = emotion.div({
  backgroundColor: '#212121',
  color: 'white',
  position: 'relative',
  height: 50,
  ...flex.vertical,
  ...flex.centerVertical,
  [queries.mediumWidth]: {
    height: 'auto',
    minHeight: 50
  }
});

export const Wrapper = emotion($Wrapper)({
  padding: '0px 50px',
  ...flex.horizontal,
  ...flex.centerHorizontal,
  ...flex.spaceBetween,
  [queries.mediumWidth]: {
    ...flex.verticalInverse,
    ...flex.spaceBetween,
    flex: 1,
    padding: '10px 20px'
  }
});

export const Credits = emotion.div({
  color: 'gray',
  fontSize: 12,
  width: '100%',
  ...flex.horizontal,
  ...flex.centerHorizontal,
  [queries.mediumWidth]: {
    fontSize: 10
  },
  '& a': {
    color: '#a9a9a9'
  },
  '& *': {
    marginRight: 5
  }
});
