import React, { Component, Fragment } from 'react';

//glamorous
import { StudentsSection, Title, Quotes, Subtitle } from './styles';

import { Wrapper } from 'styles/shared-components';

//components
import Quote from 'components/Quote';
import Background from 'components/Background';
import Sprinkles from 'components/Sprinkles';
import ClientList from 'components/ClientList';

//config
import quotes from 'config/quotes';
import { sections } from 'styles/config';

const { attendees } = sections;

//animation
import { AnimateIn } from 'react-genie';
import { animations } from 'styles/animations';

class StudentsSectionComponent extends Component {
  render() {
    const { showTop = true, clients } = this.props;

    return (
      <StudentsSection {...attendees.section}>
        <Background {...attendees.background} />
        {attendees.sprinkles && <Sprinkles id="students-section" />}

        <Wrapper>
          {showTop && (
            <Fragment>
              <Title withSpace centered {...attendees.title}>
                Our Clients
              </Title>

              <Subtitle>
                These companies took their React expertise to the next level
              </Subtitle>

              <ClientList clients={clients} />
            </Fragment>
          )}

          <Title withSpace centered {...attendees.title}>
            Hundreds of satisfied attendees
          </Title>

          <AnimateIn
            debug={true}
            hasWrapper={true}
            delayBetween={150}
            animationName={animations.fadeInUp}
          >
            <Quotes>
              {quotes.map(quote => <Quote key={quote.quote} quote={quote} />)}
            </Quotes>
          </AnimateIn>
        </Wrapper>
      </StudentsSection>
    );
  }
}

export default StudentsSectionComponent;
