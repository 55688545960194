import { EMAIL } from './constants';

export const contact = {
  href: `mailto:${EMAIL}?subject=React Academy Workshop`
};

export const externalLink = href => ({
  href,
  target: '_blank',
  rel: 'noopener'
});
