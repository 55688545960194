import React, { Fragment, useRef, useEffect } from "react";
import { Global } from "@emotion/core";

import "modern-normalize/modern-normalize.css";

const globalStyles = `
  html,
  body {
    padding: 0;
    margin: 0;
  }

  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  @supports (--custom: property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  body {
    overflow-x: hidden;
    font-family: Cairo, sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  .animate {
    visibility: hidden;
  }
`;

const Layout = ({ children }) => {
  let loadedFont = useRef(false);

  useEffect(() => {
    if (loadedFont.current === false) {
      const WebFont = require("webfontloader");
      WebFont.load({
        google: {
          families: ["Cairo: 400, 600", "Roboto Condensed: 400, 600"]
        }
      });
      loadedFont.current = true;
    }

    if (window && window.location && window.location.pathname) {
      const url = window.location.pathname.split("/").pop();
      if (!["image", "printable"].includes(url)) {
        setTimeout(() => {
          /*loadDrift();
          setTimeout(() => {
            if (window.drift) {
              window.drift.SNIPPET_VERSION = "0.3.1";
              window.drift.load("2urfindgefrb");
            }
          }, 1000);*/
        }, 1500);
      }
    }
  }, []);

  return (
    <Fragment>
      <Global styles={globalStyles} />
      {children}
    </Fragment>
  );
};

export default Layout;
