import compact from 'lodash/compact';
import map from 'lodash/map';

export const baseURI = 'https://media.graphcms.com';

export const transform = (name, params) => {
  const transformedParams = do {
    if (params) {
      const paramz = cleanJoin(
        map(params, (param, key) => {
          if (param !== undefined && param !== null) {
            return `${key}:${param}`;
          }
        }),
        ','
      );

      `=${paramz}`;
    } else {
      ('');
    }
  };
  return `${name}${transformedParams}`;
};

export const cleanJoin = (arr, separator) => compact(arr).join(separator);

export const resize = (width, height) =>
  transform('resize', { w: width, h: height });

export const blur = amount => transform('blur', { amount });

export const compose = (handle, transforms = []) =>
  cleanJoin([baseURI, transforms.join('/'), handle], '/');
