import emotion from '@emotion/styled';
import { buttonify, unlink } from './mixins';
import colors from './colors';
import { grid, unstyleLink } from './mixins';
import { queries } from './responsive';
import flex from './flex';
import fontWeights from 'config/fontWeights';

import {
  Horizontal as $Horizontal,
  Vertical as $Vertical
} from './flex-components';
import darken from 'polished/lib/color/darken';

const titleSectionSpace = 50;

export const Horizontal = $Horizontal;
export const Vertical = $Vertical;

export const Space = emotion.div(({ size = 1 } = {}) => ({
  height: size * 5,
  width: size * 5,
  minHeight: size * 5,
  minWidth: size * 5
}));

export const Section = emotion.section(
  {
    position: 'relative',
    minHeight: 500
  },
  ({ styles }) => styles
);

export const Gradient = emotion.div({
  position: 'absolute',
  zIndex: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `linear-gradient(35deg, #75baf9, #75c8f9 50%, #4d79ff)`,
  '-webkit-backface-visibility': 'hidden'
});

export const Video = emotion.video(p => ({
  height: '102%',
  width: '100%',
  objectFit: 'cover',
  position: 'relative',
  flex: 1,
  transform: 'scale(1.01)'
}));

let whiteWithShadow = {
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.63)',
  color: 'white'
};
export const Title = emotion.div(
  {
    fontSize: 48,
    lineHeight: '50px',
    color: colors.titleColor,
    fontWeight: fontWeights.FOUR,
    [queries.smallWidth]: {
      fontSize: 35,
      lineHeight: '45px',
      textAlign: 'center'
    }
  },
  p => ({
    ...(p.withSpace && { marginBottom: titleSectionSpace }),
    ...(p.centered && { textAlign: 'center' }),
    ...(p.white && whiteWithShadow),
    ...p.styles
  })
);

export const SectionTitle = emotion(Title)({
  textAlign: 'center'
});

export const Subtitle = emotion.div(
  {
    fontSize: 28,
    lineHeight: '40px',
    margin: 'auto',
    marginTop: 25,
    marginBottom: titleSectionSpace,
    color: colors.subtitleColor,
    fontWeight: fontWeights.FOUR,
    textAlign: 'center',
    maxWidth: 800,
    width: '100%',
    [queries.largeWidth]: {
      fontSize: 20,
      lineHeight: '25px'
    }
  },
  p => ({
    ...(p.white && whiteWithShadow),
    ...(p.extraSpace && { marginBottom: 100 })
  })
);

export const smallWrapperStyles = {
  padding: `${titleSectionSpace}px 20px`
};

export const Grid = emotion.div(
  {
    ...grid(3, 70, 70, {
      fallbackParent: {
        ...flex.horizontal,
        ...flex.centerHorizontalV
      },
      fallbackChild: {
        margin: '0 30px'
      }
    })
  },
  p => ({
    [queries.to.ipad]: {
      ...grid(3, 35, {
        fallbackParent: {
          ...flex.vertical
        }
      })
    },
    [p.breakpoint || queries.to.medium]: {
      ...grid(1, p.smallGap || 70)
    }
  })
);

export const Wrapper = emotion.div({
  maxWidth: 1200,
  padding: `100px 50px`,
  margin: 'auto',
  position: 'relative',
  width: '100%',
  zIndex: 2,
  [queries.smallWidth]: smallWrapperStyles
});

export const WhiteButton = emotion.a(
  {
    ...flex.vertical,
    ...flex.centerVertical,
    fontSize: 22,
    userSelect: 'none',
    minHeight: 61,
    textAlign: 'center',
    outline: 'none',
    border: 'none',
    padding: '10px 40px',
    cursor: 'pointer',
    transition: 'all 150ms linear',
    borderRadius: 10,
    ...unlink,
    [queries.mediumWidth]: {
      fontSize: 23,
      padding: '10px 30px'
    },
    backgroundColor: 'white',
    color: '#474747'
  },
  ({ disabled, size }) => {
    return {
      ...(size === 'small' && {
        minHeight: 50,
        fontSize: 20,
        padding: '5px 15px'
      }),
      ...(disabled && {
        opacity: 0.5,
        cursor: 'not-allowed'
      }),
      ...(!disabled && {
        '&:active': {
          transform: 'translateY(2px)'
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: darken(0.07, 'white')
        }
      })
    };
  }
);

export const Link = emotion.a({
  color: 'white',
  marginTop: 10,
  textDecoration: 'none',
  paddingBottom: 1,
  borderBottom: `1px dashed rgba(255,255,255, 0.3)`
});

export const Button = emotion.button(
  buttonify,
  unstyleLink(),
  {
    fontSize: 28,
    padding: '10px 25px',
    transition: 'all 100ms linear',
    textAlign: 'center',
    borderWidth: 3,
    borderStyle: 'solid'
  },
  p => ({
    borderColor: p.inverse ? 'white' : colors.primary2,
    color: p.inverse ? 'white' : 'black',
    '&:hover': {
      backgroundColor: p.inverse ? 'white' : colors.primary2,
      color: p.inverse ? colors.primary2 : 'white'
    }
  })
);

export const Quote = emotion.div({
  textAlign: 'center',
  fontSize: 25,
  color: '#919eaf',
  width: '100%',
  maxWidth: 800,
  margin: 'auto',
  marginTop: 30,
  marginBottom: 80,
  [queries.mediumWidth]: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 25
  }
});

export const TopicBlocks = emotion.div(({ number = 3 }) => ({
  ...(number <= 2
    ? {
        ...flex.horizontal,
        ...flex.centerHorizontal,
        [queries.mediumWidth]: {
          ...flex.vertical,
          ...flex.centerVertical
        }
      }
    : {
        ...grid(number <= 2 ? 2 : 3, 50),
        [queries.largeWidth]: {
          ...grid(2, 50)
        },
        [queries.mediumWidth]: {
          ...grid(1, 0, 50)
        }
      })
}));
