import React, { Component } from "react";
import { Observer, Enter } from "react-genie";
import { animations } from "styles/animations";

import { Clients, Client, ClientImage } from "./styles";

class ClientList extends Component {
  render() {
    const { clients } = this.props;

    return (
      <Observer triggerOnce={true}>
        {({ inView, ref }) => (
          <Clients ref={ref}>
            {clients.map((client, index) => (
              <Enter
                key={client.id}
                visible={inView}
                delay={100 * index}
                animationName={animations.fadeIn}
              >
                <Client>
                  <ClientImage inView={inView} logo={client.logo} />
                </Client>
              </Enter>
            ))}
          </Clients>
        )}
      </Observer>
    );
  }
}

export default ClientList;
