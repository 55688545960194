import emotion from '@emotion/styled';
import { fixedSize } from 'styles/mixins';
import flex from 'styles/flex';
import colors from 'styles/colors';

export const TopicBlock = emotion.div({
  ...flex.vertical,
  ...flex.centerVerticalH
});

export const Inside = emotion.div(
  {
    ...flex.vertical
  },
  p => ({
    ...(p.centered && {
      ...flex.centerVertical,
      maxWidth: 350
    })
  })
);

export const Title = emotion.div(
  {
    fontSize: 38,
    lineHeight: '40px',
    margin: '18px 0',
    color: colors.titleColor
  },
  p => ({
    ...(p.centered && {
      textAlign: 'center'
    })
  })
);

export const LogoWrap = emotion.div({
  position: 'relative',
  borderRadius: '100%',
  backgroundColor: 'white',
  ...(false && {
    boxShadow: '-4px 4px 5px 0px rgba(0, 0, 0, 0.11)'
  }),
  ...(false && {
    border: '1px solid #c5c4c4',
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.17)'
  }),
  ...(true && {
    boxShadow: '-5px 0px 7px 0px rgba(0, 0, 0, 0.08)',
    border: '1px solid #dedede'
  }),
  ...fixedSize(80),
  ...flex.vertical,
  ...flex.centerVertical,
  overflow: 'hidden',
  '&:not(:only-child)': {
    marginRight: 20
  },
  zIndex: 0,
  ':nth-child(2)': {
    left: -50,
    marginRight: -25,
    zIndex: 1
  },
  ':nth-child(3)': {
    left: -50,
    zIndex: 2
  }
});

export const Logo = emotion.img(({ width = '100%', styles }) => ({
  width,
  ...styles
}));

export const Description = emotion.div(
  {
    color: colors.subtitleColor,
    fontSize: 17,
    lineHeight: '27px'
  },
  p => ({
    ...(p.centered && {
      textAlign: 'center'
    })
  })
);

export const Logos = emotion.div(
  {
    ...flex.horizontal,
    position: 'relative'
  },
  ({ centered }) => ({
    ...(centered && {
      ...flex.centerHorizontal
    })
  })
);
