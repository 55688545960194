import React, { Component } from 'react';
import { IS_DEV } from 'config/constants';

//glamorous
import { Video, Loading, Placeholder } from './styles';

//images
import loaderImg from 'images/svg-icons/three-dots.svg';

class VideoComponent extends Component {
  state = {
    loaded: false
  };

  video;

  setLoaded = () => {
    this.setState({ loaded: true });
  };

  onLoad = () => {
    const { onLoad } = this.props;
    this.setLoaded();
    if (onLoad) {
      onLoad();
    }
  };

  storeRef = video => {
    const { delay = 0 } = this.props;
    if (video && !this.video) {
      this.video = video;
      if (video.readyState >= video.HAVE_FUTURE_DATA) {
        this.onLoad();
      } else {
        video.addEventListener('canplay', () => {
          setTimeout(() => {
            this.onLoad();
          }, IS_DEV ? delay : 0);
        });
      }
    }
  };

  renderVideo = () => {
    const { src, className, style } = this.props;
    const { loaded } = this.state;

    return (
      <video
        autoPlay
        playsInline
        loop
        muted
        src={src}
        className={className}
        style={{
          ...(!loaded && {
            display: 'none',
            visibility: 'hidden'
          }),
          ...(loaded && {}),
          ...style
        }}
        ref={this.storeRef}
      />
    );
  };

  render() {
    const {
      className,
      placeholderColor = '#2e2d2e',
      width,
      style,
      height
    } = this.props;

    const { loaded } = this.state;

    return (
      <Video
        style={style}
        placeholderColor={placeholderColor}
        className={className}
      >
        {!loaded && (
          <Placeholder
            placeholderColor={placeholderColor}
            style={style}
            width={width}
            height={height}
            className={className}
          >
            <Loading src={loaderImg} />
          </Placeholder>
        )}
        {this.renderVideo()}
      </Video>
    );
  }
}

export default VideoComponent;
