import React, { Component } from 'react';
import { externalLink } from 'config/props';
import { queries } from 'styles/responsive';

//glamorous
import {
  TryLabsSection,
  Note,
  Grid,
  LinkButton,
  Wrapper,
  WorkshopPoint
} from './styles';

//img
import codeIcon from 'images/svg-icons/code.svg';
import vocabularyIcon from 'images/svg-icons/vocabulary.svg';
import questionsIcon from 'images/svg-icons/questions.svg';

//animation
import { AnimateIn } from 'react-genie';
import { animations } from 'styles/animations';

class TryLabsSectionComponent extends Component {
  render() {
    const { allowTry = false } = this.props;

    return (
      <TryLabsSection>
        <Wrapper>
          <AnimateIn
            hasWrapper={true}
            duration={500}
            delayBetween={500}
            animationName={animations.fadeIn}
          >
            <Grid smallGap={30} breakpoint={queries.to.large}>
              <WorkshopPoint
                icon={questionsIcon}
                title="Task"
                description={`Each exercise in the labs is clearly defined, and split into multiple steps if needed.`}
              />

              <WorkshopPoint
                icon={vocabularyIcon}
                title="Preview"
                description={`On the right side there is a live preview of the component that is expected to be built by the students.`}
              />

              <WorkshopPoint
                icon={codeIcon}
                title="Solution"
                description={`If someone is impatient, stuck, or just wants to compare the results, they can always check the hints, or see the solution.`}
              />
            </Grid>
          </AnimateIn>

          {allowTry && (
            <Note>
              *Note: React Academy is not an online training. The Labs app is
              just a companion app to the on-site training. <br />
              After each presentation in the workshop, the attendees go back to
              their laptops and do the exercises.
            </Note>
          )}
          {allowTry && (
            <LinkButton {...externalLink('https://labs.reactacademy.io')}>
              Try Labs
            </LinkButton>
          )}
        </Wrapper>
      </TryLabsSection>
    );
  }
}

export default TryLabsSectionComponent;
