import emotion from '@emotion/styled';
import { queries } from 'styles/responsive';

import {
  Section,
  Wrapper as $Wrapper,
  Grid as $Grid
} from 'styles/shared-components';

import $Video from 'components/Video';
import $Laptop from 'components/Laptop';

export const LabsSection = emotion(Section)({
  marginBottom: 230,
  [queries.mediumWidth]: {
    marginBottom: 0
  },
  [queries.smaller.medium]: {
    paddingBottom: 275
  }
});

export const Wrapper = emotion($Wrapper)({});

export const Grid = emotion($Grid)({
  marginTop: 100
});

export const Laptop = emotion($Laptop)(
  {
    width: '100%',
    maxWidth: 1000,
    margin: 'auto',
    [queries.mediumWidth]: {
      display: 'none'
    }
  },
  p => ({
    ...(p.pulled && {
      position: 'relative',
      top: -460,
      marginBottom: -460
    }),
    ...(p.pulledBottom && {
      position: 'relative',
      top: 30,
      marginBottom: -340
    })
  })
);

/*
 margin-bottom: 0;
 padding-bottom: 400px;
 */

export const Video = emotion($Video)(
  {
    width: '100%'
  },
  p => ({
    ...(p.withoutLaptop && {
      [queries.smaller.medium]: {
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        boxShadow: `-2px 4px 8px rgba(0, 0, 0, 0.15)`
      },
      [queries.aboveMedium]: {
        display: 'none'
      }
    })
  })
);
