import React from 'react';
import {
  Laptop,
  Top,
  Shadow,
  Bottom,
  Webcam,
  Screen,
  Children
} from './styles';

export default ({ children, style, className }) => (
  <Laptop style={style} className={className}>
    <Shadow />
    <Top>
      <Webcam />
      <Screen>
        <Children>{children}</Children>
      </Screen>
    </Top>
    <Bottom />
  </Laptop>
);
