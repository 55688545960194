const pinkSet = {
  primary1: '#d36260',
  primary2: '#d1756d',
  primary3: '#c49782'
};

const blueSet = {
  primary1: '#326998',
  primary2: '#326084',
  primary3: '#73aad8'
};

const colors = {
  ...blueSet,
  titleColor: '#32325d',
  subtitleColor: '#6b7c93',
  textColor: '#6b7c93',
  buttonColor: '#447fb9'
};

export default colors;
