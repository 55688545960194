import { Font } from './config-setup';
import { horizontalGradientFromLight } from './mixins';
import { queries } from './responsive';
import colors from './colors';

export const font = new Font({
  name: 'Cairo',
  weights: {
    normal: 400,
    bold: 600
  },
  subset: 'latin'
});

export const gradients = {
  orangeFun: 'linear-gradient(to right, #fc4a1a, #f7b733)',
  digitalWater: 'linear-gradient(to right, #74ebd5, #acb6e5)',
  lithium: 'linear-gradient(to right, #6d6027, #d3cbb8)',
  argon: 'linear-gradient(to right, #03001e, #7303c0, #ec38bc, #fdeff9)',
  hydrogen: 'linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)',
  zinc: 'linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea)',
  velvetSun: 'linear-gradient(to right, #e1eec3, #f05053)',
  kingYna: 'linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d)',
  summer: 'linear-gradient(to right, #22c1c3, #fdbb2d)',
  orangeCoral: 'linear-gradient(to right, #ff9966, #ff5e62)',
  purpink: 'linear-gradient(to right, #7f00ff, #e100ff)',
  dull: 'linear-gradient(to right, #c9d6ff, #e2e2e2)',
  kimoby: 'linear-gradient(to right, #396afc, #2948ff)',
  brokenHearts: 'linear-gradient(to right, #d9a7c7, #fffcdc)',
  purpleToBlue: 'linear-gradient(to right, #667db6, #0082c8)'
};

const whiteWithSprinkles = {
  title: {
    styles: {
      color: colors.titleColor
    }
  },
  subtitle: {
    white: false
  },
  background: {
    gradient: true,
    skewed: true
  },
  sprinkles: true,
  section: {
    styles: {
      marginTop: 50
    }
  }
};

export const sections = {
  main: {
    background: {
      custom: {
        background: gradients.hydrogen
      }
    }
  },
  workshopTypes: {},
  workshop: {
    ...whiteWithSprinkles,
    section: {
      name: 'Workshop'
    }
  },
  labs: {
    background: {
      custom: {
        background: gradients.hydrogen
      },
      skewed: true
    },
    title: {
      white: true
    },
    subtitle: {
      white: true
    },
    laptop: {
      pulledBottom: true
    },
    section: {
      styles: {}
    }
  },
  attendees: {
    ...whiteWithSprinkles,
    section: {
      styles: {
        marginTop: 50
      }
    }
  },
  faqSection: {
    /*...whiteWithSprinkles,
    section: {
      styles: {
        marginTop: 50
      }
    }*/
  },
  gallery: {
    section: {
      skewed: true
    }
  },
  topics: {
    background: {
      // skewed: true,
      topDown: true
    },
    sprinkles: true,
    title: {
      styles: {
        color: colors.titleColor
      }
    },
    section: {
      marginTop: 0
    }
  },
  coach: {
    background: {
      custom: {
        ...horizontalGradientFromLight('#afafaf', 0.6),
        [queries.largeWidth]: {
          background: 'none',
          backgroundColor: '#f1f1f1'
        }
      },
      skewed: false
    },
    avatar: {
      rotated: false
    }
  },
  contact: {}
};
