import React, { Component } from 'react';
import createCompile from 'config/compile-markdown';

import * as S from './styles';
import Background from 'components/Background';
import Sprinkles from 'components/Sprinkles';
import { sections } from 'styles/config';
const { faqSection } = sections;

const compile = createCompile({
  a({ children, ...rest }) {
    return (
      <S.Link {...rest} target="_blank" rel="noopener">
        {children}
      </S.Link>
    );
  }
});

class FaqView extends Component {
  render() {
    const { questions = [] } = this.props;
    return (
      <S.FaqView {...faqSection.section}>
        <Background {...faqSection.background} />
        {faqSection.sprinkles && <Sprinkles id="students-section" />}

        <S.Wrap>
          <S.Title centered>Frequently Asked Questions</S.Title>
          <S.Questions>
            {questions.map(faq => (
              <S.Question key={faq.id}>
                <S.QuestionTitle>{faq.question}</S.QuestionTitle>
                <S.Answer> {compile(faq.answer).tree} </S.Answer>
              </S.Question>
            ))}
          </S.Questions>
        </S.Wrap>
      </S.FaqView>
    );
  }
}

export default FaqView;
