import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { Wrapper as $Wrapper } from 'styles/shared-components';

export const Header = emotion.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  height: 60,
  top: 0,
  zIndex: 99999,
  width: '100%',
  backgroundColor: '#3f6071',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.4)'
});

export const Wrapper = emotion($Wrapper)({
  padding: '0 50px',
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  ...flex.spaceBetween,
  width: '100%',
  height: '100%',
  zIndex: 99999
});

export const Link = emotion.div({
  color: 'white',
  marginRight: 30,
  fontSize: 20,
  textShadow: '1px 1px 5px rgba(0, 0, 0, 0.4)',
  '&:last-child': {
    marginRight: 0
  },
  '&:hover': {
    cursor: 'pointer'
  }
});

export const Links = emotion.div({
  ...flex.horizontal
});

export const Right = emotion.div({});

export const QuoteButton = emotion.button({});
