import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { unlink } from 'styles/mixins';
import { queries } from 'styles/responsive';
import { Link } from '@reach/router';

import SvgLogo from 'components/SvgLogo';

export const A = emotion(Link)({
  ...unlink
});

export const Image = emotion(SvgLogo)({
  maxWidth: 550,
  width: '100%'
});

export const Logo = emotion.div(
  {
    ...flex.horizontal,
    color: 'white',
    [queries.mediumWidth]: {
      // fontSize: 50
    },
    [queries.smallWidth]: {
      // fontSize: 38
    }
  },
  p => ({
    ...(p.collapsed && {
      fontSize: 30,
      cursor: 'pointer',
      opacity: 0.9,
      transition: 'all 150ms linear',
      [queries.mediumWidth]: {
        fontSize: 30
      },
      [queries.smallWidth]: {
        fontSize: 25
      },
      '&:hover': {
        opacity: 1
      }
    })
  })
);

export const Comp = emotion.span({
  opacity: 0.6
});
