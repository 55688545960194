import emotion from '@emotion/styled';
import { queries } from 'styles/responsive';
import flex from 'styles/flex';

import * as A from 'styles/shared-components';

//components
import fontWeights from 'config/fontWeights';

export const Text = emotion.div({
  color: 'white',
  textShadow: '1px 1px 5px rgba(0,0,0,0.4)',
  textAlign: 'center'
});

export const SingleWorkshopSection = emotion.div({
  position: 'relative',
  backgroundColor: '#678fa4'
});

export const Subtitle = emotion(Text)({
  fontSize: 20,
  lineHeight: '25px',
  textAlign: 'center'
});

export const Logo = emotion.img(
  {
    ...flex.horizontal,
    width: 'auto',
    maxHeight: 60,
    maxWidth: 200
  },
  p => ({
    ...p.styles
  })
);

export const DatesButtons = emotion.div({
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  [queries.belowLarge]: {
    ...flex.vertical,
    ...flex.centerVertical
  }
});

export const Title = emotion(Text)({
  fontSize: 40,
  lineHeight: '48px'
});

export const Wrapper = emotion.div({
  maxWidth: 1200,
  padding: `100px 50px`,
  margin: 'auto',
  position: 'relative',
  width: '100%',
  zIndex: 3,
  [queries.smallWidth]: {
    padding: '50px 25px'
  }
});

export const Description = emotion(Text)({
  textAlign: 'center',
  fontSize: 23,
  lineHeight: '33px',
  fontWeight: fontWeights.FOUR,
  maxWidth: 600
});

export const DateButton = emotion(A.WhiteButton)({
  minWidth: 230,
  [queries.belowLarge]: {
    padding: '10px 17px',
    fontSize: 19,
    lineHeight: '27px'
  }
});

export const LocationDateText = emotion(Text)({
  fontSize: 25,
  lineHeight: '25px'
});

export const Location = emotion(LocationDateText)({});
export const Date = emotion(LocationDateText)({});

export const Separator = emotion(LocationDateText)({
  color: 'white',
  width: 10,
  height: 10,
  backgroundColor: 'white',
  borderRadius: '100%',
  opacity: 0.7
  // boxShadow: '1px 1px 5px rgba(0,0,0,0.4)'
});

export const Collaboration = emotion(Text)({
  fontSize: 18,
  lineHeight: '35px'
});

export const CollaborationBreak = emotion.br({
  [queries.smaller.medium]: {
    display: 'none'
  }
});

export const PrintableLink = emotion(A.Link)({
  fontSize: 14
});

export const WorkshopLink = emotion(A.Link)({
  paddingBottom: 0,
  borderBottom: `1px dashed rgba(255,255,255, 0.8)`
});

export const ConfDescription = emotion.div({
  textAlign: 'center',
  maxWidth: 350,
  margin: 'auto'
});
