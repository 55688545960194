import map from 'lodash/map';

export class Font {
  constructor({ name, weights, subset }) {
    this.name = name;
    this.weights = weights;
    this.subset = subset;
  }

  name;
  weights;
  subset;

  getLink() {
    let weights = this.weights ? `:${map(this.weights, w => w).join(',')}` : '';
    const googleLink = `//fonts.googleapis.com/css?family=`;
    return `${googleLink}${this.name}${weights}&subset=${this.subset}`;
  }
}
