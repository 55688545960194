import React, { Component } from "react";

//glamorous
import {
  WorkshopSection,
  Video,
  Title,
  WorkshopPoints,
  WorkshopAndVideo,
  Subtitle,
  WorkshopPoint,
  Laptop,
  HorizontalPoints
} from "./styles";

import { Wrapper } from "styles/shared-components";
import { sections } from "styles/config";

//videos
import presentationVideo from "videos/presentation.mp4";

import { animations } from "styles/animations";

//components
import Background from "components/Background";
import Sprinkles from "components/Sprinkles";

//animation
import { Observer, Enter } from "react-genie";

//icons
import bookIcon from "images/svg-icons/book.svg";
import questionIcon from "images/svg-icons/question.svg";
import videoIcon from "images/svg-icons/video.svg";

const { workshop } = sections;

class SecondSection extends Component {
  render() {
    return (
      <Observer threshold={0.6} triggerOnce={true}>
        {({ inView, ref }) => (
          <HorizontalPoints ref={ref}>
            <Enter
              visible={inView}
              duration={200}
              animationName={animations.fadeInLeft}
            >
              <WorkshopPoint
                icon={bookIcon}
                title="Up-to-date"
                description={`The presentations and exercises are always updated with the latest findings, standards, and best practices.`}
              />
            </Enter>
            <Enter
              visible={inView}
              duration={200}
              animationName={animations.fadeInRight}
            >
              <WorkshopPoint
                icon={questionIcon}
                title="Q&A session"
                description={`After each presentation, anyone can ask questions about the presented material. We'll make sure that everything is clear before moving on.`}
              />
            </Enter>
          </HorizontalPoints>
        )}
      </Observer>
    );
  }
}

class WorkshopSectionComponent extends Component {
  render() {
    const { styles } = this.props;
    const { section } = workshop;

    return (
      <WorkshopSection
        id={section.name}
        styles={{ ...section.styles, ...styles }}
        {...section}
      >
        {workshop.background && <Background {...workshop.background} />}
        {workshop.sprinkles && (
          <Sprinkles id="workshop-section" contain {...workshop.sprinkles} />
        )}

        <Wrapper>
          <Title {...workshop.title}> A different kind of workshop</Title>
          <Subtitle {...workshop.subtitle}>
            Made in a way to keep the attendees focused and intrigued
          </Subtitle>
          <WorkshopPoints>
            <Observer threshold={0.6} triggerOnce={true}>
              {({ inView, ref }) => (
                <WorkshopAndVideo ref={ref}>
                  <Enter
                    visible={inView}
                    duration={200}
                    animationName={animations.fadeInLeft}
                  >
                    <WorkshopPoint
                      icon={videoIcon}
                      hideIconOnDesktop
                      title="Dynamic presentations"
                      description={`The presentations include scrollable code snippets and interactive React components. Not quite the regular PowerPoint slides from the 90s that will make you fall asleep.`}
                    />
                  </Enter>
                  <Enter
                    visible={inView}
                    duration={200}
                    animationName={animations.fadeInRight}
                  >
                    <Laptop>
                      <Video
                        placeholderColor="#2e2d2e"
                        height={591}
                        width={1006}
                        delay={5000}
                        src={presentationVideo}
                      />
                    </Laptop>
                  </Enter>
                  <Enter
                    visible={inView}
                    duration={200}
                    animationName={animations.fadeInRight}
                  >
                    <Video
                      withoutLaptop
                      placeholderColor="#2e2d2e"
                      height={591}
                      width={1006}
                      delay={5000}
                      src={presentationVideo}
                    />
                  </Enter>
                </WorkshopAndVideo>
              )}
            </Observer>

            <SecondSection />
          </WorkshopPoints>
        </Wrapper>
      </WorkshopSection>
    );
  }
}

export default WorkshopSectionComponent;
