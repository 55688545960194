import devices from './devices';

//default
const MEDIA = '@media';
const ALL = 'all';
const MOBILE_PREFIX = `${MEDIA} handheld, screen`;

//width
const MIN_WIDTH = 'min-width';
const MAX_WIDTH = 'max-width';

//height
const MIN_HEIGHT = 'min-height';
const MAX_HEIGHT = 'max-height';

//orientation
const LANDSCAPE = `and (orientation: landscape)`;
const PORTRAIT = `and (orientation: portrait)`;

//functions
export const minWidth = x => `and (${MIN_WIDTH}: ${x}px)`;
export const maxWidth = x => `and (${MAX_WIDTH}: ${x}px)`;
export const minHeight = x => `and (${MIN_HEIGHT}: ${x}px)`;
export const maxHeight = x => `and (${MAX_HEIGHT}: ${x}px)`;
export const maxDeviceSize = device =>
  `${maxWidth(device.width)} ${maxHeight(device.height)}`;
export const minDeviceSize = device =>
  `${minWidth(device.width)} ${minHeight(device.height)}`;
export const maxSize = (x, y = x) => `${maxWidth(x)} ${maxHeight(y)}`;
export const rotated = ({ height, width }) => ({
  width: height,
  height: width
});

export const helpers = {
  minWidth,
  maxWidth,
  rotated,
  minHeight,
  maxHeight,
  maxDeviceSize,
  minDeviceSize,
  maxSize,
  landscape: LANDSCAPE,
  portrait: PORTRAIT,
  mobilePrefix: MOBILE_PREFIX,
  media: MEDIA,
  all: ALL
};

export const media = (...rules) => {
  return `${MEDIA} ${ALL} ${rules.join(' ')}`;
};

let smallestWidth = 320;
let smallWidth = 500;
let mediumWidth = 600;
let ipadWidth = 768;
let largeWidth = 900;
let largerWidth = 1100;

export const breakpoints = {
  small: smallWidth,
  medium: mediumWidth,
  ipad: ipadWidth,
  large: largeWidth,
  larger: largerWidth,
  smallest: smallestWidth
};

export function generateMedia() {
  return Object.keys(breakpoints).reduce(
    (acc, label) => {
      const size = breakpoints[label];
      acc.to[label] = `${MOBILE_PREFIX} ${maxWidth(size)}`;
      acc.from[label] = `${MOBILE_PREFIX} ${minWidth(size)}`;

      acc.larger[label] = `${MOBILE_PREFIX} ${minWidth(size + 1)}`;
      acc.smaller[label] = `${MOBILE_PREFIX} ${maxWidth(size - 1)}`;

      acc.range[label] = Object.keys(breakpoints).reduce(
        (toAcc = {}, toLabel) => {
          if (toLabel !== label) {
            const toSize = breakpoints[toLabel];
            let query = `${MOBILE_PREFIX} ${minWidth(size)} ${maxWidth(
              toSize
            )}`;
            toAcc[toLabel] = query;
          }
          return toAcc;
        },
        {}
      );

      return acc;
    },
    { to: {}, larger: {}, smaller: {}, from: {}, range: {} }
  );
}

export const fromWidth = size => `${MOBILE_PREFIX} ${minWidth(size)}`;
export const toWidth = size => `${MOBILE_PREFIX} ${maxWidth(size)}`;
export const rangeWidth = (from, to) =>
  `${MOBILE_PREFIX} ${minWidth(from)} ${maxWidth(to)}`;

export const queries = {
  landscape: `${MOBILE_PREFIX} ${LANDSCAPE}`,
  largeWidth: `${MOBILE_PREFIX} ${maxWidth(largeWidth)}`,
  mediumWidth: `${MOBILE_PREFIX} ${maxWidth(mediumWidth)}`,
  smallWidth: `${MOBILE_PREFIX} ${maxWidth(smallWidth)}`,
  largerWidth: `${MOBILE_PREFIX} ${maxWidth(largerWidth)}`,
  fromLargeToMedium: `${MOBILE_PREFIX} ${maxWidth(largeWidth)} ${minWidth(
    mediumWidth
  )}`,
  fromMediumToSmall: `${MOBILE_PREFIX} ${maxWidth(mediumWidth)} ${minWidth(
    smallWidth
  )}`,
  fromLargeToSmall: `${MOBILE_PREFIX} ${maxWidth(largeWidth)} ${minWidth(
    smallWidth
  )}`,
  fromSmallToMedium: `${MOBILE_PREFIX} ${minWidth(smallWidth)} ${maxWidth(
    mediumWidth
  )}`,
  aboveSmall: `${MOBILE_PREFIX} ${minWidth(smallWidth)}`,
  aboveMedium: `${MOBILE_PREFIX} ${minWidth(mediumWidth + 1)}`,
  aboveLarge: `${MOBILE_PREFIX} ${minWidth(largeWidth)}`,
  belowLarge: `${MOBILE_PREFIX} ${maxWidth(largeWidth)}`,
  landscapeBelowLargeWidth: `${MOBILE_PREFIX} ${maxWidth(
    largeWidth
  )} ${LANDSCAPE}`,
  ...generateMedia()
};
