import emotion from '@emotion/styled';
import { queries } from 'styles/responsive';
import flex from 'styles/flex';

const frameSize = 22;

export const Laptop = emotion.div({
  ...flex.vertical,
  position: 'relative',
  ...flex.centerVerticalV,
  minHeight: 250,
  flex: 2,
  width: '100%',
  [queries.largeWidth]: {
    // display: 'none'
  }
});

export const Children = emotion.div({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  ...flex.vertical
});

export const Bottom = emotion.div({
  height: 25,
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
  backgroundColor: '#CADCD6',
  zIndex: 3,
  flexShrink: 0
});

const webcamSize = 5;

export const Webcam = emotion.div({
  width: webcamSize,
  height: 5,
  backgroundColor: 'white',
  borderRadius: '50%',
  position: 'absolute',
  top: frameSize / 2 - webcamSize / 2,
  left: 0,
  right: 0,
  margin: 'auto'
});

export const Screen = emotion.div({
  backgroundColor: '#2B82C9',
  flex: 1,
  width: '100%',
  height: '100%'
});

export const Top = emotion.div({
  width: '90%',
  margin: 'auto',
  flex: 1,
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  backgroundColor: '#252525',
  position: 'relative',
  padding: frameSize
});

export const Shadow = emotion.div({
  WebkitFilter: 'blur(30px)',
  left: 37,
  right: 50,
  top: 33,
  height: '100%',
  opacity: '.3',
  background: '#4c4c4c',
  filter: 'blur(30px)',
  position: 'absolute',
  margin: 'auto',
  zIndex: 0
});
