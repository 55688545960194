import React, { Component } from 'react';
import random from 'lodash/random';
import times from 'lodash/times';
import config from './config';

//glamorous
import { Sprinkles, Sprinkle } from './styles';

class SprinklesComponent extends Component {
  getSprinkles = () => {
    return times(random(5, 100), t => ({
      style: {
        top: random(1, 2000),
        left: random(1, 2000)
      },
      index: t
    }));
  };

  constructor(props) {
    super(props);
    const sprinkles = this.getSprinkles();
    this.state = {
      counter: 0,
      sprinkles
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { contain, id } = this.props;
    const sprinkles = config[id];

    return (
      <Sprinkles contain={contain}>
        {sprinkles &&
          sprinkles.map(sprinkle => (
            <Sprinkle
              key={sprinkle.index}
              onClick={this.refresh}
              id={sprinkle.index}
              style={sprinkle.style}
            />
          ))}
      </Sprinkles>
    );
  }
}

export default SprinklesComponent;
