import React, { Component } from 'react';
import emotion from '@emotion/styled';

const LazyImage = emotion.div(
  {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  ({ styles }) => styles
);

const opacity = ({ show }) => ({
  opacity: show ? 1 : 0
});

export const Placeholder = emotion.div({
  width: '100%',
  transition: 'opacity 200ms linear'
});

export const Image = emotion.img(
  {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'all 300ms linear'
  },
  opacity
);

export const Temp = emotion.img(
  {
    width: '100%',
    height: '100%',
    flex: 1,
    zIndex: 9999999,
    transition: 'all 200ms linear',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1
  },
  opacity,
  ({ blur }) => ({
    ...(blur && {
      filter: 'blur(10px)'
    })
  })
);

class LazyImageComponent extends Component {
  state = { loaded: false, tempLoaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  onTempLoad = () => this.setState({ tempLoaded: true });

  render() {
    const { loaded, tempLoaded } = this.state;

    const {
      //config
      url,
      startLoading,
      tempUrl,
      loadTemp = true,
      width,
      height,
      tempBlur = true,
      ratioPlaceholder = true,
      addPlaceholder = true,
      onClick,

      //styles
      style,
      className,
      styles = {}
    } = this.props;

    const ratio = width / height;

    return (
      <LazyImage
        onClick={onClick}
        style={style}
        styles={styles}
        className={className}
      >
        {addPlaceholder && (
          <Placeholder
            style={{
              paddingBottom: `${100 / (ratioPlaceholder ? ratio : 1.333)}%`
            }}
          />
        )}

        {startLoading &&
          loadTemp && (
            <Temp
              onLoad={this.onTempLoad}
              blur={tempBlur}
              show={tempLoaded && !loaded}
              src={tempUrl}
            />
          )}

        {(loadTemp ? tempLoaded : true) &&
          startLoading && (
            <Image
              className={className}
              show={loaded}
              onLoad={this.onLoad}
              src={url}
            />
          )}
      </LazyImage>
    );
  }
}

export default LazyImageComponent;
