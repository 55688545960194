import React from 'react';

//glamorous
import { Quote, Text, From, Separator, Person, Company } from './styles';

const QuoteComponent = ({ quote, style, className }) => (
  <Quote style={style} className={className} flex={quote.flex}>
    <Text>{quote.quote}</Text>
    <From>
      <Person>{quote.name || 'Anonymous'}</Person>
      <Separator> - </Separator>
      <Company>{quote.company}</Company>
    </From>
  </Quote>
);

export default QuoteComponent;
