import React, { Component } from "react";

//glamorous
import {
  WorkshopEmailSubscribe,
  Button,
  Title,
  Input,
  Thanks,
  Form
} from "./styles";

class WorkshopEmailSubscribeComponent extends Component {
  state = {
    email: "",
    disabled: false
  };

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { withSpace } = this.props;
    const { email, disabled } = this.state;

    return (
      <WorkshopEmailSubscribe withSpace={withSpace}>
        <Title>
          Want to get notified when a workshop is available in your area?
        </Title>
        {do {
          if (disabled === false) {
            <div id="revue-embed">
              <Form
                action="https://www.getrevue.co/profile/reactacademy/add_subscriber"
                method="post"
                id="revue-form"
                name="revue-form"
                target="_blank"
                onSubmit={() => this.setState({ disabled: true })}
              >
                <div className="revue-form-group">
                  <Input
                    className="revue-form-field"
                    placeholder="Email"
                    type="email"
                    name="member[email]"
                    id="member_email"
                  />
                </div>
                <div className="revue-form-actions">
                  <Button
                    type="submit"
                    value="go"
                    name="member[subscribe]"
                    id="member_submit"
                  />
                </div>
              </Form>
            </div>;
          } else {
            <Thanks> Thank you. You will be notified. </Thanks>;
          }
        }}
      </WorkshopEmailSubscribe>
    );
  }
}

export default WorkshopEmailSubscribeComponent;
