import emotion from '@emotion/styled';

import { queries } from 'styles/responsive';
import { fadeInAnimation } from 'styles/animations';
import { translate, underParent } from 'styles/mixins';
import { ANIMATION_STEPS } from 'config/animation-steps';

export const Wrap = emotion.div({
  width: '100%',
  maxWidth: 550
});

export const Logo = emotion.svg({
  zIndex: 99999
});

const moveTextAnimation = {
  transition: 'all 700ms linear',
  ...underParent(ANIMATION_STEPS.MOVE_TEXT, translate(0, 'X'))
};

export const ReactText = emotion.path(
  {
    ...translate(20, 'X'),
    [queries.smallWidth]: {
      ...translate(10, 'X')
    }
  },
  moveTextAnimation
);

export const AcademyText = emotion.path(
  {
    ...translate(40, 'X'),
    [queries.smallWidth]: {
      ...translate(20, 'X')
    }
  },
  moveTextAnimation
);

const showAnimation = {
  opacity: 0,
  ...underParent(ANIMATION_STEPS.ANIMATE_BRACKETS, {
    animation: `${fadeInAnimation(0.7)} 600ms forwards ease-in-out`
  })
};

export const LeftBracket = emotion.polygon(showAnimation);
export const RightBracket = emotion.path(showAnimation);
