import React, { Component } from "react";
import { css } from "emotion";
import { queries } from "styles/responsive";

const animated = css({
  [queries.mediumWidth]: {
    animationName: "none !important",
    visibility: "visible !important"
  },
  [queries.aboveMedium]: {
    visibility: "hidden",
    animationFillMode: "forwards"
  }
});

class Enter extends Component {
  state = {
    visible: this.props.visible
  };

  static defaultProps = {
    delay: 0,
    duration: 1000
  };

  componentWillReceiveProps(nextProps) {
    const { visible, delay = 0 } = nextProps;
    if (visible === true) {
      setTimeout(() => this.setState({ visible: true }), delay);
    }
  }

  render() {
    const { children, duration, animationName } = this.props;
    const { visible } = this.state;

    const childrenStyle = children && children.props && children.props.style;

    const childrenClassName =
      children && children.props && children.props.className
        ? children.props.className
        : "";

    return React.cloneElement(children, {
      style: {
        visibility: visible ? "visible" : "hidden",
        animationDuration: `${duration}ms`,
        ...(visible && { animationName }),
        ...childrenStyle
      },
      className: `${visible && animationName} ${childrenClassName} ${animated}`
    });
  }
}

export default Enter;
