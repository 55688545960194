import emotion from '@emotion/styled';
import random from 'lodash/random';

export const Sprinkles = emotion.div(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  p => ({
    ...(p.contain && { overflow: 'hidden' })
  })
);

export const Sprinkle = emotion.div(
  {
    borderRadius: 3,
    backgroundColor: 'white',
    position: 'absolute'
  },
  () => {
    const height = random(80, 100);
    const width = height / 1.3;

    let sprinkleConfig = {
      height,
      minHeight: height,
      width,
      minWidth: width,
      opacity: random(0.1, 0.4)
    };
    return sprinkleConfig;
  }
);
