import React, { Component } from 'react';

//glamorous
import * as S from './styles';

//components
import LogoComponent from 'components/Logo';

class HeaderComponent extends Component {
  render() {
    return (
      <S.Header>
        <LogoComponent collapsed={true} />
        {/* <S.Links>
            <S.Link>Workshops</S.Link>
            <S.Link>Workshops</S.Link>
          </S.Links>*/}
      </S.Header>
    );
  }
}

export default HeaderComponent;
