import React, { Component } from "react";

//components
import WorkshopSection from "components/WorkshopSection";
import LabsSection from "components/LabsSection";
import TryLabsSection from "components/TryLabsSection";
import StudentsSection from "components/StudentsSection";
import BasicReactTopicsSection from "components/BasicReactTopicsSection";
import TrainerSection from "components/TrainerSection";
import FooterSection from "components/FooterSection";
import SingleWorkshopSection from "components/SingleWorkshopSection";
import HeaderComponent from "components/Header";
import WorkshopEmailSubscribeComponent from "components/WorkshopEmailSubscribe";
import Page from "components/Page";
import FaqView from "components/FaqView";
import Layout from "components/Layout";

class Workshop extends Component {

  render() {
    const { pageContext = {} } = this.props;

    const { workshop, workshopExtra = {}, settings } = pageContext;

    if (!(workshop && workshop.workshopType)) {
      return null;
    }

    const { meta } = workshopExtra;

    const { workshopType } = workshop;
    const { prerequisite, topics } = workshopType;
    const { slug } = workshop;

    return (
      <Layout>
        <Page
          pageTitle="React Academy Workshop"
          title={meta.title}
          description={meta.description}
          url={`https://reactacademy.io/workshop/${slug}`}
          facebookImageUrl={`https://react-academy-meta.netlify.com/${slug}__facebook.jpg`}
          twitterImageUrl={`https://react-academy-meta.netlify.com/${slug}__twitter.jpg`}
        >
          <HeaderComponent />
          <SingleWorkshopSection workshop={workshop} />
          <BasicReactTopicsSection topics={topics} pre={prerequisite} />
          <FaqView questions={workshopType.questions} />
          <StudentsSection showTop={false} />
          <WorkshopSection styles={{ marginTop: 0 }} />
          <LabsSection />
          <TryLabsSection allowTry={false} />
          <TrainerSection instructor={settings.instructor} />
          <WorkshopEmailSubscribeComponent withSpace={true} />
          <FooterSection />
        </Page>
      </Layout>
    );
  }
}

export default Workshop;
