import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

class Layout extends Component {
  render() {
    const {
      children,
      description,
      imageUrl,
      twitterImageUrl = imageUrl,
      facebookImageUrl = imageUrl,
      url,
      title = 'React Academy',
      pageTitle = title
    } = this.props;

    return (
      <Fragment>
        <Helmet title={pageTitle}>
          <meta name="description" content={description} />
          <meta name="image" content={imageUrl} />
          {/* Item prop */}
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content={imageUrl} />
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:site" content="@thekitze" />
          <meta name="twitter:creator" content="@thekitze" />
          <meta name="twitter:image:src" content={twitterImageUrl} />
          {/* Facebook */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={facebookImageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content={title} />
          <meta property="og:type" content="website" />
        </Helmet>
        {children}
      </Fragment>
    );
  }
}

export default Layout;
