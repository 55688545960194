import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { queries } from 'styles/responsive';

const theme = 'white';

export const Quote = emotion.div(
  p => ({
    flex: p.flex
  }),
  {
    ...flex.vertical,
    ...flex.centerVerticalV,
    borderRadius: 5,
    margin: 10,
    padding: '10px 20px',
    minWidth: 300,
    maxWidth: 600,
    transition: 'all 100ms linear',
    ...(theme === 'gray' && {
      border: '1px solid #d8d8d8'
    }),
    ...(theme === 'white' && {
      backgroundColor: 'white',
      boxShadow: `0 0 5px rgba(0, 0, 0, 0.1)`,
      '&:hover': {
        transform: 'scale(1.025)',
        boxShadow: `0 0 12px rgba(0, 0, 0, 0.15)`
      }
    }),
    [queries.smallWidth]: {
      margin: '10px 0',
      minWidth: 'auto',
      flex: 1
    }
  }
);

export const Company = emotion.div({});

export const Text = emotion.span({
  fontSize: 18,
  lineHeight: '28px',
  color: '#3e3e3e'
});

export const Person = emotion.span({});

export const From = emotion.div({
  ...flex.horizontal,
  color: 'gray'
});

export const Separator = emotion.span({
  margin: '0 2px'
});
