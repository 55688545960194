import emotion from '@emotion/styled';
import {
  Section,
  Title as $Title,
  Subtitle as $Subtitle
} from 'styles/shared-components';
import flex from 'styles/flex';
import { queries } from 'styles/responsive';

//components
import $Laptop from 'components/Laptop';
import $WorkshopPoint from 'components/WorkshopPoint';
import $Video from 'components/Video';

export const WorkshopSection = emotion(Section)(
  {
    marginTop: 100,
    minHeight: 500,
    ...flex.vertical
  },
  ({ styles }) => styles
);

export const WorkshopPoint = emotion($WorkshopPoint)({
  marginRight: 60,
  flex: 1.2,
  [queries.largeWidth]: {
    marginTop: 100,
    marginRight: 0,
    minWidth: 'auto'
  },
  [queries.mediumWidth]: {
    ...flex.centerVertical,
    minWidth: 'auto',
    width: '100%',
    margin: 0,
    textAlign: 'center',
    marginTop: 50
  }
});

export const Title = emotion($Title)({
  fontSize: 40,
  marginBottom: 30,
  textAlign: 'center'
});

export const Subtitle = emotion($Subtitle)({
  marginBottom: 150,
  [queries.largeWidth]: {
    marginBottom: 45
  }
});

export const Laptop = emotion($Laptop)({
  [queries.mediumWidth]: {
    display: 'none'
  }
});

export const Video = emotion($Video)(
  {
    width: '100%'
  },
  p => ({
    ...(p.withoutLaptop && {
      [queries.aboveMedium]: {
        display: 'none'
      }
    })
  })
);

export const WorkshopPoints = emotion.div({
  ...flex.vertical,
  flex: 1
});

export const HorizontalPoints = emotion.div({
  ...flex.horizontal,
  [queries.largeWidth]: {
    ...flex.vertical
  }
});

export const WorkshopAndVideo = emotion.div(
  {
    ...flex.horizontal,
    ...flex.centerHorizontalV,
    marginBottom: 150,
    [queries.largeWidth]: {
      marginBottom: 0
    }
  },
  p => ({
    [queries.largeWidth]: {
      ...flex.verticalInverse
    }
  })
);
