import emotion from '@emotion/styled';
import {
  Section,
  Wrapper as $Wrapper,
  TopicBlocks as $TopicBlocks
} from 'styles/shared-components';

export const TopicsSection = emotion(Section)`
   
`;

export const Wrapper = emotion($Wrapper)({});

export const TopicBlocks = emotion($TopicBlocks)({});
