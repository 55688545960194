import emotion from '@emotion/styled';

export const Video = emotion.div(
  {
    display: 'flex',
    flexDirection: 'column'
  },
  p => ({
    backgroundColor: p.placeholderColor
  })
);

export const Loading = emotion.img({
  width: 60,
  margin: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
});

export const Placeholder = emotion.div(
  {
    position: 'relative'
  },
  p => ({
    backgroundColor: p.placeholderColor,
    paddingBottom: `${(p.height / p.width) * 100}%`
  })
);
