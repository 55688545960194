import React, { Component, Fragment } from 'react';

//glamorous
import { TopicsSection, TopicBlocks, Wrapper } from './styles';
import { Title, Subtitle } from 'styles/shared-components';

//components
import TopicBlock from 'components/TopicBlock';

import * as A from 'styles/shared-components';

import { sections } from 'styles/config';
const { topics: topicsSection } = sections;

//animation
import { AnimateIn } from 'react-genie';
import { animations } from 'styles/animations';

class BasicReactTopicsSection extends Component {
  static defaultProps = {
    pre: `The only prerequisite for this workshop is good understanding of JavaScript. Everything else will be explained from scratch.`
  };

  render() {
    const { pre, topics = [] } = this.props;
    const isCentered = true;

    return (
      <TopicsSection {...topicsSection.section}>
        {/*<Background {...topicsSection.background} />*/}
        {/*{topicsSection.sprinkles && <Sprinkles id="topics-section" {...topicsSection.sprinkles} />}*/}

        <Wrapper>
          <Title centered {...topicsSection.title}>
            Included topics
          </Title>

          <Subtitle {...topicsSection.subtitle}>
            A general overview of what's going to be included in the workshop
          </Subtitle>

          <AnimateIn
            hasWrapper={true}
            animationName={animations.fadeIn}
            delayBetween={200}
          >
            <TopicBlocks number={topics.length}>
              {topics.map((topic, index) => (
                <Fragment>
                  <TopicBlock centered={isCentered} key={index} topic={topic} />
                  {topics.length === 2 &&
                    index !== topics.length - 1 && <A.Space size={10} />}
                </Fragment>
              ))}
            </TopicBlocks>
          </AnimateIn>
        </Wrapper>
      </TopicsSection>
    );
  }
}

export default BasicReactTopicsSection;
