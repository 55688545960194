import React, { Component } from "react";

import createCompile from "config/compile-markdown";

//glamorous
import {
  TrainerSection,
  Left,
  Title,
  Text,
  Name,
  Avatar,
  Wrapper,
  Container,
  Link
} from "./styles";

//external
import LazyLoad from "components/LazyLoader";
import Background from "components/Background";

import { sections } from "styles/config";
const { coach } = sections;

//animation
import { Observer, Enter } from "react-genie";
import { animations } from "styles/animations";

//images
import kitzeSmallImage from "images/kitze-small.png";
import kitzeImage from "images/kitze.png";

const compile = createCompile({
  a({ children, ...rest }) {
    return (
      <Link {...rest} target="_blank" rel="noopener">
        {children}
      </Link>
    );
  }
});

class TrainerSectionComponent extends Component {
  render() {
    const { instructor } = this.props;

    return (
      <TrainerSection>
        <Background {...coach.background} />

        <Wrapper>
          <Observer triggerOnce={true}>
            {({ inView, ref }) => (
              <Container ref={ref}>
                <Enter visible={inView} animationName={animations.fadeInLeft}>
                  <Left>
                    <Title> Meet your coach </Title>
                    <Name> Kristijan Ristovski a.k.a Kitze </Name>
                    <LazyLoad height={150}>
                      <Avatar top src={kitzeSmallImage} />
                    </LazyLoad>
                    <Text>{compile(instructor).tree}</Text>
                  </Left>
                </Enter>
                <LazyLoad height={400}>
                  <Enter visible={inView} animationName={animations.fadeInUp}>
                    <Avatar {...coach.avatar} bottom src={kitzeImage} />
                  </Enter>
                </LazyLoad>
              </Container>
            )}
          </Observer>
        </Wrapper>
      </TrainerSection>
    );
  }
}

export default TrainerSectionComponent;
