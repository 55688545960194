import { css, keyframes } from 'emotion';
import reduce from 'lodash/reduce';

export const moveLeftAnimation = keyframes({
  '0%': {},
  '100%': { transform: `translateX(0)` }
});

export const moveRightAnimation = keyframes({
  '0%': {},
  '100%': { transform: `translateX(0)` }
});

export const fadeInAnimation = (opacity = 1) =>
  keyframes({
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity
    }
  });

const defaultAnimations = {
  fadeInLeft: require('react-animations/lib/fadeInLeft').default,
  fadeInRight: require('react-animations/lib/fadeInRight').default,
  fadeInUp: require('react-animations/lib/fadeInUp').default,
  fadeIn: require('react-animations/lib/fadeIn').default
};

export const animations = reduce(
  defaultAnimations,
  (accum, object, name) => {
    object.to.visibility = 'visible';
    accum[name] = keyframes(object);
    return accum;
  },
  {}
);
