import React from "react";

import { InView } from "react-intersection-observer";
import Enter from "./Enter";

const ANIMATION_NAME_PROP = "animation-name";
const ANIMATION_DELAY_PROP = "animation-delay";

const getChildren = ({ children, animation, ref, delay, duration, inView }) => {
  return React.Children.map(children, (child, index) => {
    const { props = {} } = child;
    const animationName = props[ANIMATION_NAME_PROP] || animation;
    const animationDelay = props[ANIMATION_DELAY_PROP] || delay;

    return (
      <Enter
        ref={ref}
        visible={inView}
        duration={duration}
        delay={index * animationDelay}
        animationName={animationName}
      >
        {child}
      </Enter>
    );
  });
};

const AnimateIn = ({
  threshold = 0,
  duration = 1000,
  id,
  onChange,
  children,
  animationName: animation,
  delayBetween: delay,
  tag = "div"
}) => {
  return (
    <InView
      onChange={onChange}
      id={id}
      tag={tag}
      threshold={threshold}
      triggerOnce={true}
    >
      {({ inView, ref }) => {
        const everyChildrenHasAnimationName = React.Children.map(
          children,
          child => !!child.props[ANIMATION_NAME_PROP]
        ).every(Boolean);

        const syntaxIsValid = animation || everyChildrenHasAnimationName;

        if (!syntaxIsValid) {
          return console.error(
            'You either must provide an "animation" prop to AnimateIngroup, or each of the sub-children must have a "data-animation-name" property.'
          );
        }

        return React.cloneElement(
          children,
          { ref },
          getChildren({
            children: children.props.children,
            animation,
            delay,
            duration,
            inView
          })
        );
      }}
    </InView>
  );
};

export default AnimateIn;
