import React, { Component } from 'react';

//glamorous
import LazyImage from 'components/LazyImage';
import { compose, resize, blur, transform } from 'utils/image-utils';

class GraphCmsImage extends Component {
  render() {
    const {
      logo,
      inView,
      resizeConfig = {
        w: 300
      },
      blurAmount = 10,
      style,
      logoStyle,
      className,
      tempBlur,
      loadTemp,
      onClick,
      ratioPlaceholder,
      addPlaceholder,
      extraTransforms = []
    } = this.props;

    if (!logo) {
      return null;
    }

    const { handle, height, width } = logo;

    const tempImageSrc = compose(
      handle,
      [resize(20, 20), blur(blurAmount), 'compress']
    );

    const imageSrc = compose(
      handle,
      [transform('resize', resizeConfig), ...extraTransforms, 'compress']
    );

    return (
      <LazyImage
        addPlaceholder={addPlaceholder}
        ratioPlaceholder={ratioPlaceholder}
        onClick={onClick}
        loadTemp={loadTemp}
        tempBlur={tempBlur}
        height={height}
        width={width}
        startLoading={inView}
        url={imageSrc}
        tempUrl={tempImageSrc}
        //styles
        style={style}
        styles={logoStyle}
        className={className}
      />
    );
  }
}

export default GraphCmsImage;
