import format from 'date-fns/format';
import isSameDay from 'date-fns/is_same_day';

export const getWorkshopDate = (
  dateStart,
  dateEnd,
  dateFormat = 'DD MMMM, YYYY',
  fromToFormat = 'DD.MM.YY'
) => {
  let sameDay = isSameDay(dateStart, dateEnd);
  const hasBothDates = dateStart && dateEnd && !sameDay;

  return hasBothDates
    ? `${format(dateStart, fromToFormat)} - ${format(dateEnd, fromToFormat)}`
    : format(dateStart, dateFormat);
};
