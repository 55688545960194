import emotion from '@emotion/styled';

//utils
import { queries } from 'styles/responsive';
import { grid } from 'styles/mixins';
import flex from 'styles/flex';

//components
import GraphCmsImage from 'components/GraphCmsImage';

export const Client = emotion.div({
  width: '100%',
  maxHeight: 100,
  minHeight: 100,
  ...flex.vertical,
  ...flex.centerVertical
});

export const ClientImage = emotion(GraphCmsImage)({
  maxWidth: 270,
  maxHeight: 100,
  [queries.smallWidth]: {
    maxWidth: 180
  }
});

export const Clients = emotion.div({
  margin: '90px 0',
  ...grid(4, 100, 50, {
    fallbackParent: {
      ...flex.horizontal
    }
  }),
  [queries.largeWidth]: {
    ...grid(3, 70)
  },
  [queries.mediumWidth]: {
    ...grid(2, 30, 30),
    margin: 0,
    marginBottom: 40
  }
});
