import emotion from '@emotion/styled';
import flex from 'styles/flex';
import colors from 'styles/colors';
import { queries } from 'styles/responsive';

export const WorkshopPoint = emotion.div({
  ...flex.vertical
});

export const Title = emotion.div({
  fontSize: 45,
  color: colors.titleColor,
  lineHeight: '50px',
  [queries.mediumWidth]: {
    fontSize: 26,
    textAlign: 'left',
    lineHeight: '30px',
    margin: '20px 0'
  }
});

export const Icon = emotion.img(
  {
    marginRight: 20,
    width: 70,
    [queries.mediumWidth]: {
      marginRight: 15,
      width: 45
    }
  },
  p => ({
    ...(p.hideIconOnDesktop && {
      [queries.from.large]: {
        display: 'none'
      }
    })
  })
);

export const Description = emotion.div({
  fontSize: 22,
  color: colors.subtitleColor,
  marginTop: 10,
  lineHeight: '30px',
  [queries.mediumWidth]: {
    fontSize: 18,
    lineHeight: '27px',
    textAlign: 'justify',
    marginTop: 0
  }
});

export const IconAndTitle = emotion.div({
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  [queries.mediumWidth]: {
    width: '100%',
    marginBottom: 10
  }
});
