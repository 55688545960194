import marksy from 'marksy';
import React, { createElement } from 'react';

const createCompile = elements =>
  marksy({
    createElement,
    elements: {
      a({ children, ...rest }) {
        return (
          <a {...rest} target="_blank" rel="noopener">
            {children}
          </a>
        );
      },
      ...elements
    }
  });

export default createCompile;
