import React from 'react';
import LazyLoad from 'react-lazyload';

export default ({ children, offset = 1500, height = 300 }) => {
  return (
    <LazyLoad once={true} offset={offset} height={height}>
      {children}
    </LazyLoad>
  );
};
