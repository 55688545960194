const flex = [
  2,
  10,
  1,
  8,
  3,
  3,
  4,
  4,
  3,
  9,
  2,
  4,
  4,
  4,
  7,
  7,
  6,
  7,
  7,
  5,
  4,
  3,
  5
];

const finalFlex = [...flex, ...flex];

const quotesArray = [
  {
    name: 'Jip Moors',
    company: 'Yoast BV',
    quote: 'Comprehensive, good level of engagement throughout the day.'
  },
  {
    name: 'Eric V.D. Helm',
    company: 'Capgemini',
    quote:
      'Good, learned a lot! Workshops contained a lot of exercises which were great.'
  },
  {
    company: 'Amazee Labs',
    quote: 'Excellent deep dive into complex material.'
  },
  {
    name: 'Ton Hordyk',
    company: 'Capgemini',
    quote:
      'Learned a lot! Good introduction to the basics of React. The best part of the training were the labs.'
  },
  {
    company: 'Amazee Labs',
    quote:
      'We learned a lot for a week of training. It was a really good, full of new information, training in general. I see it as a very good starting point for further React development.'
  },
  {
    name: 'Rodrigo Lopes',
    company: 'OLX',
    quote:
      'It was dynamic, learned a lot, and met new tools. Best part were the explanations about using CSS in React.'
  },
  {
    name: 'Arjan van IJzendoorn ',
    company: 'Mendix',
    quote: 'Good introduction. Looking forward to the advanced training!'
  },
  {
    company: 'Amazee Labs',
    quote:
      'Very good overview of React and all tools around it. Diving into some more advanced topics and comparison of different tools and options instead of just looking at one thing.'
  },
  {
    name: 'Nathan Bourke ',
    company: 'Launch Academy',
    quote:
      'Very thorough introduction to React. I enjoyed the ES6 overview and styling in React.'
  },
  {
    company: 'Amazee Labs',
    quote: 'Very well laid out. Quite fast pace but able to keep up.'
  },
  {
    name: 'Sebastian Kopp',
    company: 'Launch Academy',
    quote:
      'The presentations were very straightforward, especially with the format of the slides.'
  },
  {
    name: 'Denis Augsbirger',
    company: 'Triple W Team',
    quote:
      'It was a really good start with React and the labs were pretty interesting.'
  },
  {
    name: 'Maria Voinea',
    company: 'ING',
    quote:
      'The training was very nice. Much better than I expected actually. The best part were the interactivity and enthusiasm.'
  },
  {
    name: 'Danny Terwindt',
    company: 'Yoast',
    quote: 'A good intro to React, with some nice in-depth things.'
  },
  {
    name: 'Omar Reiss',
    company: 'Yoast',
    quote:
      'Good introduction! After the training I can start building something.'
  },
  {
    company: 'Amazee Labs',
    quote:
      'Wish it was longer... some of the days I wanted to just continue going until the night instead of going back to work.'
  },
  {
    name: 'Mark Poelstra',
    company: 'Clockwork',
    quote:
      'Very informative. The best part was the diversity between the presentations and the labs.'
  },
  {
    company: 'Quatico',
    quote:
      'Cool trainer, well structured course, live coding to answer questions was great.'
  },
  {
    company: 'dotJS',
    quote: 'Very good instructor!'
  },
  {
    company: 'dotJS',
    quote:
      'I liked the depth of the covered material. Knowledgeable instructor with real-world experience in React.'
  },
  {
    company: 'dotJS',
    quote: `I'm not a big user of JS and React to fully appreciate the content of the course, but it makes me want to learn more about it.`
  },
  {
    company: 'React Fest',
    quote: 'Thorough, actionable, enthusiastic.'
  },
  {
    company: 'React Fest',
    quote: "Awesome to realised I don't know even 10% of React"
  },
  {
    company: 'React Fest',
    quote:
      'I really liked the workshop, I learned lot of things. I was definitely worth it.'
  },
  {
    company: 'React Fest',
    quote: 'Better than expected due to the amount of topics that we could see.'
  },
  {
    company: 'React Fest',
    quote: 'Good flow of topics, very well organised.'
  },
  {
    company: 'React Fest',
    quote: 'I had fun and learned new things. Really useful and inspiring.'
  },
  {
    company: 'React Fest',
    quote:
      'Lots of stuff to learn, and a lot of time to learn and test. Nice training!'
  },
  {
    company: 'React Fest',
    quote: 'Good overview of new React concepts and API.'
  },
  {
    company: 'React Fest',
    quote: 'I loved it. It would be better if we had more time.'
  },
  {
    company: 'React Fest',
    quote: 'The training was really good, the instructor is great.'
  },
  {
    company: 'React Fest',
    quote: 'Most of it was pretty sick.'
  },
  {
    company: 'React Fest',
    quote: 'Content is advanced enough yet still clear and understandable.'
  },
  {
    company: 'React Fest',
    quote: 'Very well-structured workshops!'
  },
  {
    company: 'React Fest',
    quote:
      'I liked how we learned small parts, getting gratually more complicated, just to use them all at once in a justified manner.'
  },
  {
    company: 'React Fest',
    quote:
      'Kitze is clearly passionate about his craft and he has a great teaching style.'
  }
];

const quotes = quotesArray.map((quote, index) => ({
  ...quote,
  flex: finalFlex[index]
}));

export default quotes;
