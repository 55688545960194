export default {
  'workshop-section': [
    {
      style: {
        top: 836,
        left: 491
      },
      index: 0
    },
    {
      style: {
        top: 1333,
        left: 47
      },
      index: 1
    },
    {
      style: {
        top: 699,
        left: 855
      },
      index: 2
    },
    {
      style: {
        top: 1861,
        left: 512
      },
      index: 3
    },
    {
      style: {
        top: 188,
        left: 438
      },
      index: 4
    },
    {
      style: {
        top: 1914,
        left: 802
      },
      index: 5
    },
    {
      style: {
        top: 1533,
        left: 472
      },
      index: 6
    },
    {
      style: {
        top: 1034,
        left: 708
      },
      index: 7
    },
    {
      style: {
        top: 553,
        left: 10
      },
      index: 8
    },
    {
      style: {
        top: 38,
        left: 1673
      },
      index: 9
    },
    {
      style: {
        top: 1550,
        left: 1372
      },
      index: 10
    },
    {
      style: {
        top: 1651,
        left: 78
      },
      index: 11
    },
    {
      style: {
        top: 1266,
        left: 1585
      },
      index: 12
    },
    {
      style: {
        top: 1857,
        left: 1351
      },
      index: 13
    },
    {
      style: {
        top: 1716,
        left: 1988
      },
      index: 14
    },
    {
      style: {
        top: 683,
        left: 1167
      },
      index: 15
    },
    {
      style: {
        top: 1447,
        left: 315
      },
      index: 16
    },
    {
      style: {
        top: 1243,
        left: 1393
      },
      index: 17
    },
    {
      style: {
        top: 891,
        left: 761
      },
      index: 18
    },
    {
      style: {
        top: 1558,
        left: 564
      },
      index: 19
    },
    {
      style: {
        top: 1468,
        left: 164
      },
      index: 20
    },
    {
      style: {
        top: 695,
        left: 297
      },
      index: 21
    },
    {
      style: {
        top: 572,
        left: 973
      },
      index: 22
    },
    {
      style: {
        top: 432,
        left: 133
      },
      index: 23
    },
    {
      style: {
        top: 1100,
        left: 1062
      },
      index: 24
    },
    {
      style: {
        top: 416,
        left: 1107
      },
      index: 25
    },
    {
      style: {
        top: 1526,
        left: 1541
      },
      index: 26
    },
    {
      style: {
        top: 1946,
        left: 902
      },
      index: 27
    },
    {
      style: {
        top: 28,
        left: 638
      },
      index: 28
    },
    {
      style: {
        top: 1136,
        left: 1782
      },
      index: 29
    },
    {
      style: {
        top: 1020,
        left: 1215
      },
      index: 30
    },
    {
      style: {
        top: 1158,
        left: 431
      },
      index: 31
    },
    {
      style: {
        top: 282,
        left: 1164
      },
      index: 32
    },
    {
      style: {
        top: 1056,
        left: 854
      },
      index: 33
    },
    {
      style: {
        top: 1770,
        left: 956
      },
      index: 34
    },
    {
      style: {
        top: 1893,
        left: 270
      },
      index: 35
    },
    {
      style: {
        top: 1120,
        left: 1689
      },
      index: 36
    },
    {
      style: {
        top: 1715,
        left: 361
      },
      index: 37
    },
    {
      style: {
        top: 1651,
        left: 1710
      },
      index: 38
    },
    {
      style: {
        top: 879,
        left: 799
      },
      index: 39
    },
    {
      style: {
        top: 63,
        left: 815
      },
      index: 40
    },
    {
      style: {
        top: 1006,
        left: 407
      },
      index: 41
    },
    {
      style: {
        top: 1873,
        left: 64
      },
      index: 42
    },
    {
      style: {
        top: 1982,
        left: 311
      },
      index: 43
    },
    {
      style: {
        top: 1895,
        left: 1578
      },
      index: 44
    },
    {
      style: {
        top: 377,
        left: 186
      },
      index: 45
    },
    {
      style: {
        top: 280,
        left: 547
      },
      index: 46
    },
    {
      style: {
        top: 649,
        left: 270
      },
      index: 47
    },
    {
      style: {
        top: 1931,
        left: 1965
      },
      index: 48
    },
    {
      style: {
        top: 1476,
        left: 1823
      },
      index: 49
    },
    {
      style: {
        top: 786,
        left: 818
      },
      index: 50
    },
    {
      style: {
        top: 635,
        left: 838
      },
      index: 51
    },
    {
      style: {
        top: 583,
        left: 971
      },
      index: 52
    },
    {
      style: {
        top: 191,
        left: 760
      },
      index: 53
    },
    {
      style: {
        top: 1067,
        left: 465
      },
      index: 54
    },
    {
      style: {
        top: 138,
        left: 1966
      },
      index: 55
    },
    {
      style: {
        top: 1480,
        left: 1120
      },
      index: 56
    },
    {
      style: {
        top: 1822,
        left: 1118
      },
      index: 57
    },
    {
      style: {
        top: 1597,
        left: 1450
      },
      index: 58
    },
    {
      style: {
        top: 227,
        left: 231
      },
      index: 59
    },
    {
      style: {
        top: 1363,
        left: 540
      },
      index: 60
    },
    {
      style: {
        top: 406,
        left: 1243
      },
      index: 61
    },
    {
      style: {
        top: 1717,
        left: 1748
      },
      index: 62
    },
    {
      style: {
        top: 1905,
        left: 355
      },
      index: 63
    },
    {
      style: {
        top: 921,
        left: 155
      },
      index: 64
    },
    {
      style: {
        top: 196,
        left: 1562
      },
      index: 65
    },
    {
      style: {
        top: 80,
        left: 1273
      },
      index: 66
    },
    {
      style: {
        top: 1578,
        left: 1875
      },
      index: 67
    },
    {
      style: {
        top: 277,
        left: 188
      },
      index: 68
    },
    {
      style: {
        top: 574,
        left: 1751
      },
      index: 69
    },
    {
      style: {
        top: 449,
        left: 776
      },
      index: 70
    },
    {
      style: {
        top: 442,
        left: 1700
      },
      index: 71
    },
    {
      style: {
        top: 312,
        left: 1107
      },
      index: 72
    },
    {
      style: {
        top: 1424,
        left: 190
      },
      index: 73
    },
    {
      style: {
        top: 345,
        left: 10
      },
      index: 74
    },
    {
      style: {
        top: 266,
        left: 1499
      },
      index: 75
    },
    {
      style: {
        top: 660,
        left: 1646
      },
      index: 76
    },
    {
      style: {
        top: 1988,
        left: 1125
      },
      index: 77
    },
    {
      style: {
        top: 1986,
        left: 489
      },
      index: 78
    },
    {
      style: {
        top: 1038,
        left: 1824
      },
      index: 79
    },
    {
      style: {
        top: 59,
        left: 115
      },
      index: 80
    },
    {
      style: {
        top: 1313,
        left: 609
      },
      index: 81
    },
    {
      style: {
        top: 524,
        left: 29
      },
      index: 82
    },
    {
      style: {
        top: 1198,
        left: 622
      },
      index: 83
    },
    {
      style: {
        top: 51,
        left: 1721
      },
      index: 84
    },
    {
      style: {
        top: 42,
        left: 1250
      },
      index: 85
    },
    {
      style: {
        top: 1753,
        left: 617
      },
      index: 86
    },
    {
      style: {
        top: 5,
        left: 1305
      },
      index: 87
    }
  ],
  'students-section': [
    {
      style: {
        top: 985,
        left: 3
      },
      index: 0
    },
    {
      style: {
        top: 1271,
        left: 714
      },
      index: 1
    },
    {
      style: {
        top: 591,
        left: 1817
      },
      index: 2
    },
    {
      style: {
        top: 1122,
        left: 1385
      },
      index: 3
    },
    {
      style: {
        top: 61,
        left: 664
      },
      index: 4
    },
    {
      style: {
        top: 555,
        left: 472
      },
      index: 5
    },
    {
      style: {
        top: 408,
        left: 518
      },
      index: 6
    },
    {
      style: {
        top: 1849,
        left: 1513
      },
      index: 7
    },
    {
      style: {
        top: 785,
        left: 1012
      },
      index: 8
    },
    {
      style: {
        top: 499,
        left: 1104
      },
      index: 9
    },
    {
      style: {
        top: 1592,
        left: 737
      },
      index: 10
    },
    {
      style: {
        top: 954,
        left: 1121
      },
      index: 11
    },
    {
      style: {
        top: 1873,
        left: 285
      },
      index: 12
    },
    {
      style: {
        top: 333,
        left: 688
      },
      index: 13
    },
    {
      style: {
        top: 1493,
        left: 480
      },
      index: 14
    },
    {
      style: {
        top: 930,
        left: 1453
      },
      index: 15
    },
    {
      style: {
        top: 422,
        left: 757
      },
      index: 16
    },
    {
      style: {
        top: 1969,
        left: 1811
      },
      index: 17
    },
    {
      style: {
        top: 1908,
        left: 1068
      },
      index: 18
    },
    {
      style: {
        top: 873,
        left: 1893
      },
      index: 19
    },
    {
      style: {
        top: 23,
        left: 990
      },
      index: 20
    },
    {
      style: {
        top: 708,
        left: 694
      },
      index: 21
    },
    {
      style: {
        top: 476,
        left: 1008
      },
      index: 22
    },
    {
      style: {
        top: 1027,
        left: 983
      },
      index: 23
    },
    {
      style: {
        top: 98,
        left: 962
      },
      index: 24
    },
    {
      style: {
        top: 1779,
        left: 1873
      },
      index: 25
    },
    {
      style: {
        top: 1203,
        left: 1628
      },
      index: 26
    },
    {
      style: {
        top: 127,
        left: 733
      },
      index: 27
    },
    {
      style: {
        top: 567,
        left: 642
      },
      index: 28
    },
    {
      style: {
        top: 417,
        left: 443
      },
      index: 29
    },
    {
      style: {
        top: 267,
        left: 1672
      },
      index: 30
    },
    {
      style: {
        top: 911,
        left: 1768
      },
      index: 31
    },
    {
      style: {
        top: 1353,
        left: 1204
      },
      index: 32
    },
    {
      style: {
        top: 491,
        left: 846
      },
      index: 33
    },
    {
      style: {
        top: 596,
        left: 956
      },
      index: 34
    }
  ],
  'topics-section': [
    {
      style: {
        top: 197,
        left: 643
      },
      index: 0
    },
    {
      style: {
        top: 1507,
        left: 1196
      },
      index: 1
    },
    {
      style: {
        top: 1501,
        left: 620
      },
      index: 2
    },
    {
      style: {
        top: 89,
        left: 838
      },
      index: 3
    },
    {
      style: {
        top: 1752,
        left: 1881
      },
      index: 4
    },
    {
      style: {
        top: 585,
        left: 1277
      },
      index: 5
    },
    {
      style: {
        top: 1380,
        left: 64
      },
      index: 6
    },
    {
      style: {
        top: 686,
        left: 113
      },
      index: 7
    },
    {
      style: {
        top: 1256,
        left: 651
      },
      index: 8
    },
    {
      style: {
        top: 1329,
        left: 206
      },
      index: 9
    },
    {
      style: {
        top: 829,
        left: 813
      },
      index: 10
    },
    {
      style: {
        top: 1863,
        left: 685
      },
      index: 11
    },
    {
      style: {
        top: 669,
        left: 1791
      },
      index: 12
    },
    {
      style: {
        top: 1519,
        left: 1439
      },
      index: 13
    },
    {
      style: {
        top: 106,
        left: 1214
      },
      index: 14
    },
    {
      style: {
        top: 1303,
        left: 139
      },
      index: 15
    },
    {
      style: {
        top: 156,
        left: 1831
      },
      index: 16
    },
    {
      style: {
        top: 958,
        left: 1310
      },
      index: 17
    },
    {
      style: {
        top: 828,
        left: 801
      },
      index: 18
    },
    {
      style: {
        top: 1036,
        left: 399
      },
      index: 19
    },
    {
      style: {
        top: 1367,
        left: 1613
      },
      index: 20
    },
    {
      style: {
        top: 681,
        left: 1317
      },
      index: 21
    },
    {
      style: {
        top: 1492,
        left: 1647
      },
      index: 22
    },
    {
      style: {
        top: 678,
        left: 289
      },
      index: 23
    },
    {
      style: {
        top: 584,
        left: 1914
      },
      index: 24
    },
    {
      style: {
        top: 1578,
        left: 1948
      },
      index: 25
    },
    {
      style: {
        top: 126,
        left: 436
      },
      index: 26
    },
    {
      style: {
        top: 1405,
        left: 1681
      },
      index: 27
    },
    {
      style: {
        top: 170,
        left: 846
      },
      index: 28
    },
    {
      style: {
        top: 1193,
        left: 593
      },
      index: 29
    },
    {
      style: {
        top: 515,
        left: 989
      },
      index: 30
    },
    {
      style: {
        top: 1407,
        left: 1616
      },
      index: 31
    },
    {
      style: {
        top: 1887,
        left: 1281
      },
      index: 32
    },
    {
      style: {
        top: 1584,
        left: 1282
      },
      index: 33
    },
    {
      style: {
        top: 1701,
        left: 1828
      },
      index: 34
    },
    {
      style: {
        top: 1878,
        left: 1986
      },
      index: 35
    },
    {
      style: {
        top: 1996,
        left: 1329
      },
      index: 36
    },
    {
      style: {
        top: 174,
        left: 767
      },
      index: 37
    },
    {
      style: {
        top: 110,
        left: 1608
      },
      index: 38
    },
    {
      style: {
        top: 1343,
        left: 745
      },
      index: 39
    },
    {
      style: {
        top: 113,
        left: 1583
      },
      index: 40
    },
    {
      style: {
        top: 888,
        left: 144
      },
      index: 41
    },
    {
      style: {
        top: 1716,
        left: 510
      },
      index: 42
    },
    {
      style: {
        top: 857,
        left: 1019
      },
      index: 43
    },
    {
      style: {
        top: 381,
        left: 596
      },
      index: 44
    },
    {
      style: {
        top: 1004,
        left: 1457
      },
      index: 45
    },
    {
      style: {
        top: 455,
        left: 1099
      },
      index: 46
    },
    {
      style: {
        top: 149,
        left: 1724
      },
      index: 47
    },
    {
      style: {
        top: 604,
        left: 1858
      },
      index: 48
    },
    {
      style: {
        top: 623,
        left: 1150
      },
      index: 49
    },
    {
      style: {
        top: 1726,
        left: 655
      },
      index: 50
    },
    {
      style: {
        top: 1167,
        left: 648
      },
      index: 51
    },
    {
      style: {
        top: 1690,
        left: 1544
      },
      index: 52
    },
    {
      style: {
        top: 325,
        left: 256
      },
      index: 53
    },
    {
      style: {
        top: 552,
        left: 1956
      },
      index: 54
    },
    {
      style: {
        top: 1415,
        left: 662
      },
      index: 55
    },
    {
      style: {
        top: 1453,
        left: 625
      },
      index: 56
    },
    {
      style: {
        top: 1902,
        left: 1317
      },
      index: 57
    },
    {
      style: {
        top: 523,
        left: 137
      },
      index: 58
    },
    {
      style: {
        top: 1778,
        left: 235
      },
      index: 59
    },
    {
      style: {
        top: 1210,
        left: 828
      },
      index: 60
    },
    {
      style: {
        top: 849,
        left: 863
      },
      index: 61
    },
    {
      style: {
        top: 1968,
        left: 1550
      },
      index: 62
    },
    {
      style: {
        top: 1504,
        left: 1956
      },
      index: 63
    },
    {
      style: {
        top: 1602,
        left: 1054
      },
      index: 64
    },
    {
      style: {
        top: 971,
        left: 1165
      },
      index: 65
    },
    {
      style: {
        top: 1511,
        left: 572
      },
      index: 66
    },
    {
      style: {
        top: 179,
        left: 1848
      },
      index: 67
    },
    {
      style: {
        top: 775,
        left: 449
      },
      index: 68
    },
    {
      style: {
        top: 1799,
        left: 1068
      },
      index: 69
    },
    {
      style: {
        top: 1121,
        left: 885
      },
      index: 70
    },
    {
      style: {
        top: 680,
        left: 1407
      },
      index: 71
    },
    {
      style: {
        top: 836,
        left: 222
      },
      index: 72
    },
    {
      style: {
        top: 475,
        left: 120
      },
      index: 73
    },
    {
      style: {
        top: 712,
        left: 144
      },
      index: 74
    },
    {
      style: {
        top: 1242,
        left: 927
      },
      index: 75
    },
    {
      style: {
        top: 1060,
        left: 1994
      },
      index: 76
    },
    {
      style: {
        top: 897,
        left: 1866
      },
      index: 77
    },
    {
      style: {
        top: 795,
        left: 484
      },
      index: 78
    },
    {
      style: {
        top: 304,
        left: 1257
      },
      index: 79
    },
    {
      style: {
        top: 1288,
        left: 423
      },
      index: 80
    },
    {
      style: {
        top: 8,
        left: 1968
      },
      index: 81
    },
    {
      style: {
        top: 405,
        left: 1677
      },
      index: 82
    },
    {
      style: {
        top: 1144,
        left: 1504
      },
      index: 83
    },
    {
      style: {
        top: 1231,
        left: 1303
      },
      index: 84
    },
    {
      style: {
        top: 294,
        left: 1965
      },
      index: 85
    },
    {
      style: {
        top: 1590,
        left: 542
      },
      index: 86
    },
    {
      style: {
        top: 884,
        left: 435
      },
      index: 87
    },
    {
      style: {
        top: 713,
        left: 204
      },
      index: 88
    }
  ]
};
