import React, { Component } from 'react';

//glamorous
import { FooterSection, Wrapper, Credits } from './styles';

class FooterSectionComponent extends Component {
  render() {
    return (
      <FooterSection>
        <Wrapper>
          <Credits>
            <div>
              <span>Icons made by</span>
              <a
                target="_blank"
                rel="noopener"
                href="http://www.freepik.com"
                title="Freepik"
              >
                Freepik
              </a>
              <span>from</span>
              <a
                target="_blank"
                rel="noopener"
                href="http://www.flaticon.com"
                title="Flaticon"
              >
                www.flaticon.com
              </a>
              <span>is licensed by</span>
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                rel="noopener"
                target="_blank"
              >
                CC 3.0 BY
              </a>
            </div>
          </Credits>
          <Credits>
            <span>Powered by </span>
            <a
              href="https://graphcms.com"
              title="GraphCMS"
              target="_blank"
              rel="noopener"
            >
              GraphCMS
            </a>
          </Credits>
        </Wrapper>
      </FooterSection>
    );
  }
}

export default FooterSectionComponent;
